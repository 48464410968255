import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { arrayBinding, Flex, IconButton, Icons, IconSwitch, propertyBinding, QuickConfirm, StringField, useLocalDBinding } from "@pltk/components";
import { useInternalWarehouseConfig } from "@pltk/core";
function ChecklistPreview() {
    const config = useInternalWarehouseConfig();
    return _jsx(Flex, Object.assign({ direction: "vertical" }, { children: config.value.items.filter(it => it.show).map(it => (_jsx("div", { children: it.content }))) }));
}
function ChecklistDoneButton(props) {
    return _jsx(IconSwitch, { binding: props.binding, size: props.size, enabledIcon: _jsx(Icons.Completed, {}), disabledIcon: _jsx(Icons.Pending, {}) });
}
function ChecklistVisibleButton(props) {
    return _jsx(IconSwitch, { binding: props.binding, size: props.size, enabledIcon: _jsx(Icons.Show, {}), disabledIcon: _jsx(Icons.Hide, {}) });
}
function ChecklistConfig() {
    const configBinding = useInternalWarehouseConfig();
    const itemsBinding = arrayBinding(propertyBinding(configBinding, "items"));
    const newItemContentBinding = useLocalDBinding("");
    function createItem() {
        if (newItemContentBinding.value === "")
            return;
        const newItem = {
            content: newItemContentBinding.value,
            done: false,
            show: true
        };
        itemsBinding.insert(0, newItem);
        newItemContentBinding.update("");
    }
    return _jsxs(Flex, Object.assign({ direction: "vertical", nowrap: true, spacing: 16 }, { children: [_jsxs(Flex, Object.assign({ direction: "horizontal", nowrap: true, spacing: 8 }, { children: [_jsx(StringField, { binding: newItemContentBinding }), _jsx(IconButton, Object.assign({ onClick: createItem }, { children: _jsx(Icons.Add, {}) }))] })), itemsBinding.items.map((item, i) => (_jsxs(Flex, Object.assign({ direction: "horizontal", nowrap: true, style: { width: "100%" }, spacing: 8 }, { children: [_jsx(ChecklistDoneButton, { binding: propertyBinding(item, "done") }), _jsx(ChecklistVisibleButton, { binding: propertyBinding(item, "show") }), _jsx(StringField, { binding: propertyBinding(item, "content") }), _jsx(IconButton, { icon: _jsx(Icons.Up, {}), disabled: i === 0, onClick: () => item.move(i - 1) }), _jsx(IconButton, { icon: _jsx(Icons.Down, {}), disabled: i === itemsBinding.value.length - 1, onClick: () => item.move(i + 1) }), _jsx(QuickConfirm, Object.assign({ title: "\u786E\u8BA4\u8981\u5220\u9664\u5417", description: "\u4E4B\u540E\u65E0\u6CD5\u6062\u590D", onConfirm: () => item.remove() }, { children: _jsx(IconButton, { children: _jsx(Icons.Delete, {}) }) }))] }))))] }));
}
export const ChecklistWarehouse = {
    title: "待办清单",
    type: "builtin.checklist",
    initialize: {
        defaultConfig: () => ({
            items: []
        })
    },
    render: {
        preview: () => _jsx(ChecklistPreview, {}),
        config: () => _jsx(ChecklistConfig, {})
    }
};
