"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HStack = exports.VStack = exports.Flex = exports.Grid = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var gridAlignmentMapping = {
    left: "start",
    right: "end",
    evenly: "space-around"
};
var GridAlignmentContext = (0, react_1.createContext)("left");
function Grid(props) {
    var _a, _b, _c, _d;
    var alignment = (0, react_1.useContext)(GridAlignmentContext);
    if ("alignment" in props && props.alignment !== undefined) {
        alignment = props.alignment;
    }
    var elementProps = { style: props.style, className: props.className };
    if (props.container && "span" in props) {
        var spacing = (_a = props.spacing) !== null && _a !== void 0 ? _a : 22;
        return (0, jsx_runtime_1.jsx)(GridAlignmentContext.Provider, __assign({ value: alignment }, { children: (0, jsx_runtime_1.jsx)(antd_1.Col, __assign({}, elementProps, { span: props.span * 2 }, { children: (0, jsx_runtime_1.jsx)(antd_1.Row, __assign({ gutter: [spacing, spacing], justify: gridAlignmentMapping[alignment] }, { children: props.children })) })) }));
    }
    else if (props.container) {
        var spacing = (_b = props.spacing) !== null && _b !== void 0 ? _b : 22;
        return (0, jsx_runtime_1.jsx)(GridAlignmentContext.Provider, __assign({ value: alignment }, { children: (0, jsx_runtime_1.jsx)(antd_1.Row, __assign({}, elementProps, { gutter: [spacing, spacing], justify: gridAlignmentMapping[alignment] }, { children: props.children })) }));
    }
    else if ("span" in props) {
        return (0, jsx_runtime_1.jsx)(GridAlignmentContext.Provider, __assign({ value: alignment }, { children: (0, jsx_runtime_1.jsx)(antd_1.Col, __assign({}, elementProps, { span: props.span * 2 }, { children: props.children })) }));
    }
    else {
        throw new Error("Invalid props: container=".concat((_c = props["container"]) !== null && _c !== void 0 ? _c : false, ", item=").concat((_d = props["item"]) !== null && _d !== void 0 ? _d : false));
    }
}
exports.Grid = Grid;
var flexDirectionMapping = {
    horizontal: "row",
    vertical: "column"
};
var flexAlignmentMapping = {
    start: "flex-start",
    end: "flex-end",
    center: "flex-center",
    evenly: "space-around",
    "space-between": "space-between"
};
function Flex(props) {
    var _a, _b, _c;
    var direction = (_a = props.direction) !== null && _a !== void 0 ? _a : "horizontal";
    var reverse = (_b = props.reverse) !== null && _b !== void 0 ? _b : false;
    var wrap = !props.nowrap;
    var alignment = (_c = props.alignment) !== null && _c !== void 0 ? _c : "start";
    var children = Array.isArray(props.children) ? props.children : [props.children];
    var style = __assign({ display: "flex", flexDirection: "".concat(flexDirectionMapping[direction]).concat(reverse ? "-reverse" : ""), gap: props.spacing, flexWrap: wrap ? "wrap" : "nowrap", justifyContent: flexAlignmentMapping[alignment] }, props.style);
    return (0, jsx_runtime_1.jsx)("div", __assign({ style: style, className: props.className }, { children: children }));
}
exports.Flex = Flex;
function VStack(props) {
    var style = __assign({ gap: props.spacing, display: "grid", gridTemplateRows: props.layout.join(" "), gridTemplateColumns: "1fr" }, props.style);
    return (0, jsx_runtime_1.jsx)("div", __assign({ style: style, className: props.className }, { children: props.children }));
}
exports.VStack = VStack;
function HStack(props) {
    var style = __assign({ gap: props.spacing, display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: props.layout.join(" "), alignItems: "baseline" }, props.style);
    return (0, jsx_runtime_1.jsx)("div", __assign({ style: style, className: props.className }, { children: props.children }));
}
exports.HStack = HStack;
