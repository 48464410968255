var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DangerButton, Flex, FormItem, Grid, Icons, IconSwitch, Loading, propertyBinding, Switch, UploadButton } from "@pltk/components";
import { useRef } from "react";
import { readFileToBlob, useFileId, useLiveToolkitFileStorage, useWidgetConfigInternal } from "@pltk/core";
function EmptyImageIcon({ onClick }) {
    return _jsx("div", Object.assign({ onClick: onClick, style: { opacity: 0.5, placeItems: "center", display: "grid", width: "100%", height: "100%" } }, { children: _jsx(Icons.Image, { size: "large" }) }));
}
function renderImage(url, opacity, onClick) {
    return _jsx("div", { onClick: onClick, style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%", height: "100%",
            backgroundPosition: "center",
            position: "absolute",
            left: 0, top: 0,
            opacity,
            backgroundImage: `url('${url}')`
        } });
}
function ImageViewer() {
    const configBinding = useWidgetConfigInternal();
    const fileResult = useFileId(configBinding.value.fileId);
    if (!configBinding.value.visible) {
        return _jsx("div", {});
    }
    if (fileResult.status === "Loaded") {
        return renderImage(fileResult.url);
    }
    else if (fileResult.status === "Pending") {
        return _jsx(Loading, {});
    }
    else {
        return _jsx(EmptyImageIcon, {});
    }
}
function uploadFile(file, client) {
    return __awaiter(this, void 0, void 0, function* () {
        const blob = yield readFileToBlob(file);
        const fileId = yield client.create(blob);
        return fileId;
    });
}
function VisibleSwitch({ binding }) {
    return _jsx(IconSwitch, { binding: binding, enabledIcon: _jsx(Icons.Show, {}), disabledIcon: _jsx(Icons.Hide, {}), style: { position: "absolute", right: 0, bottom: 0 } });
}
function ImageViewerEdit() {
    const configBinding = useWidgetConfigInternal();
    const fileClient = useLiveToolkitFileStorage();
    const ref = useRef(null);
    const fileResult = useFileId(configBinding.value.fileId);
    const fileIdBinding = propertyBinding(configBinding, "fileId");
    function onChangeInternal(files) {
        return __awaiter(this, void 0, void 0, function* () {
            if (files === null || files.length === 0)
                return;
            const file = files[0];
            const newId = yield uploadFile(file, fileClient);
            yield fileIdBinding.update(newId);
        });
    }
    function onClick() {
        if (ref.current !== null) {
            ref.current.click();
        }
    }
    const fileHandler = _jsx("input", { ref: ref, type: "file", style: { display: "none" }, accept: "image/*", onChange: evt => onChangeInternal(evt.target.files) });
    if (configBinding.value.fileId === null) {
        return _jsxs(_Fragment, { children: [_jsx(EmptyImageIcon, { onClick: onClick }), fileHandler] });
    }
    if (fileResult.status === "Loaded") {
        return _jsxs(_Fragment, { children: [renderImage(fileResult.url, configBinding.value.visible ? 1 : 0.2, onClick), _jsx(VisibleSwitch, { binding: propertyBinding(configBinding, "visible") }), fileHandler] });
    }
    else if (fileResult.status === "Pending") {
        return _jsx(Loading, {});
    }
    else {
        return _jsx(EmptyImageIcon, {});
    }
}
function ImageViewerMove() {
    const configBinding = useWidgetConfigInternal();
    const fileResult = useFileId(configBinding.value.fileId);
    if (fileResult.status === "Loaded") {
        return renderImage(fileResult.url, configBinding.value.visible ? 1 : 0.2);
    }
    else if (fileResult.status === "Pending") {
        return _jsx(Loading, {});
    }
    else {
        return _jsx(EmptyImageIcon, {});
    }
}
function ImageViewerConfig() {
    const configBinding = useWidgetConfigInternal();
    const fileClient = useLiveToolkitFileStorage();
    const fileIdStore = propertyBinding(configBinding, "fileId");
    const fileResult = useFileId(fileIdStore.value);
    function onFileChange(files) {
        return __awaiter(this, void 0, void 0, function* () {
            if (files === null)
                return;
            const file = files[0];
            if (!file)
                return;
            yield fileIdStore.update(null);
            yield fileIdStore.update(yield uploadFile(file, fileClient));
        });
    }
    return _jsxs(Flex, { children: [_jsxs(Grid, Object.assign({ container: true, style: { width: "100%" } }, { children: [_jsx(Grid, Object.assign({ span: 5 }, { children: _jsx(UploadButton, Object.assign({ acceptFiles: "image/*", icon: _jsx(Icons.Upload, {}), onChange: onFileChange }, { children: "\u4E0A\u4F20\u56FE\u7247" })) })), _jsx(Grid, Object.assign({ span: 7 }, { children: _jsxs(Flex, Object.assign({ alignment: "end", spacing: 20 }, { children: [_jsx(FormItem, Object.assign({ label: "\u663E\u793A\u56FE\u7247" }, { children: _jsx(Switch, { binding: propertyBinding(configBinding, "visible") }) })), _jsx(DangerButton, Object.assign({ icon: _jsx(Icons.Delete, {}), onClick: () => fileIdStore.update(null) }, { children: "\u5220\u9664\u56FE\u7247" }))] })) }))] })), _jsx(_Fragment, { children: fileResult.status === "Loaded" ?
                    _jsx("img", { alt: "", src: fileResult.url, style: {
                            maxWidth: "min(500px, 100%)",
                            minHeight: "min(500px, 100%)",
                            opacity: configBinding.value.visible ? 1 : 0.2
                        } }) :
                    fileResult.status === "NotFound" ?
                        _jsx(EmptyImageIcon, {}) :
                        _jsx(Loading, {}) })] });
}
export const ImageViewerPlugin = {
    title: "图片展示框",
    type: "builtin.imageViewer",
    initialize: {
        defaultSize: () => ({ width: 200, height: 200 }),
        defaultConfig: () => ({ fileId: null, visible: true })
    },
    render: {
        config: () => _jsx(ImageViewerConfig, {}),
        move: () => _jsx(ImageViewerMove, {}),
        edit: () => _jsx(ImageViewerEdit, {}),
        preview: () => _jsx(ImageViewer, {})
    }
};
export default ImageViewerPlugin;
