var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Flex, IconButton, Icons, QuickConfirm, unwrapAsyncBinding, useLocalDBinding, useNullableContext, useTemporaryBinding } from "@pltk/components";
import { useContext } from "react";
import { useLiveToolkitClient, usePanelId, useWidgetConfigBinding, useWidgetId, useWidgetMeta, useWidgetRectBinding, WidgetConfigInternalProvider } from "@pltk/core";
import { useEnabledWidgets } from "@pltk/core";
import { EditableStateContext, PanelSizeContext, PreviewModeContext } from "../context";
import { EditableState } from "./base";
import { ResizableFramework, ScaledFramework } from "./frameworks";
function getEditableSwitchStyle(pluginSize, panelSize) {
    const pluginCenterX = pluginSize.x + pluginSize.width / 2;
    const pluginCenterY = pluginSize.y + pluginSize.height / 2;
    const panelCenterX = panelSize.width / 2;
    const panelCenterY = panelSize.height / 2;
    const left = pluginCenterX < panelCenterX;
    const top = pluginCenterY < panelCenterY;
    const style = { position: "absolute" };
    style[left ? "right" : "left"] = 0;
    style[top ? "top" : "bottom"] = "100%";
    return [style, !left];
}
export function DeleteButton(props) {
    const client = useLiveToolkitClient();
    const panelId = usePanelId();
    const widgetId = useWidgetId();
    return _jsx(QuickConfirm, Object.assign({ title: "\u5220\u9664\u7EC4\u4EF6", description: "\u786E\u8BA4\u8981\u5220\u9664\u7EC4\u4EF6\u5417\uFF1F", onConfirm: () => client.deleteWidget(panelId, widgetId) }, { children: _jsx(IconButton, { size: "middle", icon: _jsx(Icons.Delete, {}) }) }));
}
export function EditButton(props) {
    const panelId = usePanelId();
    const widgetId = useWidgetId();
    const showDialog = useLocalDBinding(false);
    const configBindingReq = useWidgetConfigBinding(panelId, widgetId);
    return unwrapAsyncBinding(configBindingReq, configBinding => {
        return _jsxs(_Fragment, { children: [_jsx(IconButton, Object.assign({ onClick: () => showDialog.update(true), size: "middle" }, { children: _jsx(Icons.Edit, {}) })), _jsx(EditDialogInternal, { widgetDef: props.widgetDef, showDialog: showDialog, configBinding: configBinding })] });
    });
}
function EditDialogInternal(props) {
    const [tmpConfig, saveConfig, isConfigDirty] = useTemporaryBinding(props.configBinding);
    return _jsx(_Fragment, { children: _jsx(Dialog, Object.assign({ title: `设置组件 ${props.widgetDef.title}`, onOk: () => __awaiter(this, void 0, void 0, function* () {
                yield saveConfig();
                yield props.showDialog.update(false);
            }), disableOk: !isConfigDirty, onCancel: () => props.showDialog.update(false), open: props.showDialog.value }, { children: _jsx(WidgetConfigInternalProvider, Object.assign({ configBinding: tmpConfig }, { children: props.widgetDef.render.config() })) })) });
}
export const EditableSwitch = () => {
    const editableStateBinding = useContext(EditableStateContext);
    const panelId = usePanelId();
    const widgetId = useWidgetId();
    const rectBindingReq = useWidgetRectBinding(panelId, widgetId);
    const { configSize } = useNullableContext(PanelSizeContext);
    const enabledWidgets = useEnabledWidgets();
    const meta = useWidgetMeta();
    const widgetDef = enabledWidgets[meta.type];
    return unwrapAsyncBinding(rectBindingReq, rectBinding => {
        const [style, reverse] = getEditableSwitchStyle(rectBinding.value, configSize);
        if (editableStateBinding.value === EditableState.Edit) {
            return _jsx(_Fragment, { children: _jsxs(Flex, Object.assign({ style: style, nowrap: true, reverse: reverse }, { children: [_jsx(DeleteButton, { widgetDef: widgetDef }), _jsx(EditButton, { widgetDef: widgetDef }), _jsx(IconButton, Object.assign({ size: "middle", onClick: () => editableStateBinding.update(EditableState.Move) }, { children: _jsx(Icons.Move, {}) }))] })) });
        }
        else {
            return _jsx(Flex, Object.assign({ style: style }, { children: _jsx(IconButton, Object.assign({ size: "middle", onClick: () => editableStateBinding.update(EditableState.Edit) }, { children: _jsx(Icons.Ok, {}) })) }));
        }
    });
};
const frameworkBackgroundStyle = {
    background: "linear-gradient(45deg, #00000011 25%, #00000000 0, #00000000 50%, #00000011 0, #00000011 75%, #00000000 0)",
    backgroundSize: "10px 10px"
};
const frameworkBorderStyle = {
    outlineWidth: 1,
    outlineColor: "black",
};
const editableFrameworkStyles = Object.assign(Object.assign({ outlineStyle: "solid" }, frameworkBorderStyle), frameworkBackgroundStyle);
const scaledFrameworkStyles = Object.assign(Object.assign({ outlineStyle: "dashed" }, frameworkBorderStyle), frameworkBackgroundStyle);
export const EditableBody = (props) => {
    const previewMode = useContext(PreviewModeContext);
    const panelId = usePanelId();
    const widgetId = useWidgetId();
    const rectReq = useWidgetRectBinding(panelId, widgetId);
    const editableStateBinding = useContext(EditableStateContext);
    const content = previewMode ? props.render.preview() : props.render[editableStateBinding.value]();
    const editableSwitch = _jsx(EditableSwitch, {});
    return unwrapAsyncBinding(rectReq, rectBinding => {
        if (previewMode) {
            return _jsx(ScaledFramework, Object.assign({ rect: rectBinding.value }, { children: content }));
        }
        else if (editableStateBinding.value === EditableState.Move) {
            return _jsx(ResizableFramework, Object.assign({ rect: rectBinding.value, onSizeChanged: rectBinding.update, attachments: editableSwitch, style: editableFrameworkStyles }, { children: content }));
        }
        else {
            return _jsx(ScaledFramework, Object.assign({ rect: rectBinding.value, attachments: editableSwitch, style: scaledFrameworkStyles }, { children: content }));
        }
    });
};
