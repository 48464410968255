"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadButton = exports.DangerLink = exports.LiteDangerButton = exports.DangerButton = exports.ActionButton = exports.Button = exports.IconButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
function buttonPropsToAntdProps(props) {
    return {
        icon: props.icon,
        disabled: props.disabled,
        style: props.style,
        onClick: props.onClick,
        size: props.size
    };
}
function IconButton(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ shape: "circle", type: "text" }, buttonPropsToAntdProps(props), { children: props.children }));
}
exports.IconButton = IconButton;
function Button(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "default" }, buttonPropsToAntdProps(props), { children: props.children }));
}
exports.Button = Button;
function ActionButton(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "primary" }, buttonPropsToAntdProps(props), { children: props.children }));
}
exports.ActionButton = ActionButton;
function DangerButton(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "primary", danger: true }, buttonPropsToAntdProps(props), { children: props.children }));
}
exports.DangerButton = DangerButton;
function LiteDangerButton(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "default", danger: true }, buttonPropsToAntdProps(props), { children: props.children }));
}
exports.LiteDangerButton = LiteDangerButton;
function DangerLink(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "link", danger: true }, buttonPropsToAntdProps(props), { children: props.children }));
}
exports.DangerLink = DangerLink;
function UploadButton(props) {
    var ref = (0, react_1.useRef)(null);
    return (0, jsx_runtime_1.jsxs)(antd_1.Button, __assign({ icon: props.icon, size: props.size, onClick: function () { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click(); }, style: props.style }, { children: [props.children, (0, jsx_runtime_1.jsx)("input", { ref: ref, type: "file", style: { display: "none" }, accept: props.acceptFiles, multiple: props.multiple, onChange: function (evt) {
                    props.onChange(evt.target.files);
                } })] }));
}
exports.UploadButton = UploadButton;
