var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionButton, BasicEmpty, CardWithActions, createNullableContext, Divider, FormItem, HStack, LiteDangerButton, Loading, propertyBinding, QuickConfirm, Select, SimpleCard, StringField, unwrapAsyncBinding, unwrapAsyncSubs, useLocalDBinding, useNullableContext, useTemporaryBinding } from "@pltk/components";
import { useEffect, useState } from "react";
import { useLiveToolkitClient, useWarehouseConfigBinding, useWarehouseList, useWarehouseMetaBinding } from "../backend";
const WarehouseAfterNextValidationCallbackContext = createNullableContext("Warehouse internal context");
const WarehouseDefinitionContext = createNullableContext("Warehouse* components are only available within a WarehouseProvider");
const WarehouseIdBindingContext = createNullableContext("Warehouse* components are only available within a WarehouseProvider");
export function WarehouseProvider(props) {
    const [nextValidCB, setNextValidCB] = useState([]);
    const warehouseListReq = useWarehouseList(props.warehouse.type);
    const [valid, setValid] = useState(null);
    useEffect(() => {
        if (warehouseListReq.status === "success") {
            const list = warehouseListReq.value;
            const id = props.binding.value;
            const isValid = id === null || list.some(it => it.id === id);
            setValid(isValid);
            if (!isValid) {
                props.binding.update(null);
            }
            for (const cb of nextValidCB) {
                cb();
            }
            setNextValidCB([]);
        }
        return () => setValid(null);
    }, [warehouseListReq.status === "success" ? warehouseListReq.value : undefined, props.binding.value]);
    if (valid === null) {
        return _jsx(Loading, {});
    }
    else if (!valid) {
        return _jsx(_Fragment, {});
    }
    return _jsx(WarehouseDefinitionContext.Provider, Object.assign({ value: props.warehouse }, { children: _jsx(WarehouseIdBindingContext.Provider, Object.assign({ value: props.binding }, { children: _jsx(WarehouseAfterNextValidationCallbackContext.Provider, Object.assign({ value: nextValidCB }, { children: props.children })) })) }));
}
export function WarehousePreview() {
    const idBinding = useNullableContext(WarehouseIdBindingContext);
    if (idBinding.value === null) {
        return _jsx(BasicEmpty, {});
    }
    else {
        return _jsx(WarehousePreviewInternal, { warehouseId: idBinding.value });
    }
}
function WarehousePreviewInternal({ warehouseId }) {
    const warehouseDef = useNullableContext(WarehouseDefinitionContext);
    const metaReq = useWarehouseMetaBinding(warehouseDef.type, warehouseId);
    const configReq = useWarehouseConfigBinding(warehouseDef.type, warehouseId);
    return unwrapAsyncBinding(metaReq, metaBinding => {
        return unwrapAsyncBinding(configReq, configBinding => {
            return _jsx(SimpleCard, { children: _jsx(WarehouseMetaInternalProviderContext.Provider, Object.assign({ value: metaBinding }, { children: _jsx(WarehouseConfigInternalProviderContext.Provider, Object.assign({ value: configBinding }, { children: warehouseDef.render.preview() })) })) });
        });
    });
}
export function WarehouseSelect() {
    const warehouseDef = useNullableContext(WarehouseDefinitionContext);
    const warehouseListReq = useWarehouseList(warehouseDef.type);
    const idBinding = useNullableContext(WarehouseIdBindingContext);
    return _jsx(FormItem, Object.assign({ label: warehouseDef.title }, { children: unwrapAsyncSubs(warehouseListReq, warehouseList => (_jsx(Select, { binding: idBinding, options: [
                { label: "未选择", value: null },
                ...warehouseList.map(it => ({
                    label: it.meta.title,
                    value: it.id
                }))
            ] }))) }));
}
export function WarehouseEditor() {
    const idBinding = useNullableContext(WarehouseIdBindingContext);
    if (idBinding.value === null) {
        return _jsx(CreateWarehouse, {});
    }
    else {
        return _jsx(EditWarehouse, { warehouseId: idBinding.value });
    }
}
function EditWarehouse({ warehouseId }) {
    const warehouseDef = useNullableContext(WarehouseDefinitionContext);
    const metaBindingReq = useWarehouseMetaBinding(warehouseDef.type, warehouseId);
    const configBindingReq = useWarehouseConfigBinding(warehouseDef.type, warehouseId);
    return unwrapAsyncBinding(metaBindingReq, metaBinding => {
        return unwrapAsyncBinding(configBindingReq, configBinding => {
            return _jsx(EditWarehouseInternal, { warehouseId: warehouseId, metaBinding: metaBinding, configBinding: configBinding });
        });
    });
}
function EditWarehouseInternal({ configBinding, metaBinding, warehouseId }) {
    const warehouseDef = useNullableContext(WarehouseDefinitionContext);
    const client = useLiveToolkitClient();
    const [tmpMeta, saveMeta, isMetaDirty] = useTemporaryBinding(metaBinding);
    const [tmpConfig, saveConfig, isConfigDirty] = useTemporaryBinding(configBinding);
    const idBinding = useNullableContext(WarehouseIdBindingContext);
    const titleBinding = propertyBinding(metaBinding, "title");
    function deleteWarehouse() {
        return __awaiter(this, void 0, void 0, function* () {
            yield idBinding.update(null);
            yield client.deleteWarehouse(warehouseDef.type, warehouseId);
        });
    }
    return _jsxs(CardWithActions, Object.assign({ title: `编辑 ${warehouseDef.title}`, actions: [
            _jsx(QuickConfirm, Object.assign({ title: `删除 ${warehouseDef.title}`, description: "\u6B64\u64CD\u4F5C\u65E0\u6CD5\u6062\u590D", onConfirm: deleteWarehouse }, { children: _jsx(LiteDangerButton, { children: "\u6E05\u9664" }) })),
            _jsx(ActionButton, Object.assign({ onClick: saveConfig, disabled: !isConfigDirty }, { children: "\u4FDD\u5B58" }))
        ] }, { children: [_jsxs(HStack, Object.assign({ layout: ["1fr", "auto"], spacing: 10 }, { children: [_jsx(FormItem, Object.assign({ label: "\u6807\u9898" }, { children: _jsx(StringField, { binding: titleBinding }) })), _jsx(ActionButton, Object.assign({ onClick: saveMeta, disabled: !isMetaDirty }, { children: "\u4FDD\u5B58" }))] })), _jsx(Divider, {}), _jsx(WarehouseConfigInternalProviderContext.Provider, Object.assign({ value: tmpConfig }, { children: _jsx(WarehouseMetaInternalProviderContext.Provider, Object.assign({ value: tmpMeta }, { children: warehouseDef.render.config() })) }))] }));
}
function CreateWarehouse() {
    const client = useLiveToolkitClient();
    const warehouseDef = useNullableContext(WarehouseDefinitionContext);
    const warehouseIdBinding = useNullableContext(WarehouseIdBindingContext);
    const configBinding = useLocalDBinding(warehouseDef.initialize.defaultConfig());
    const metaBinding = useLocalDBinding({ title: "" });
    const titleBinding = propertyBinding(metaBinding, "title");
    const nextValidCallbacks = useNullableContext(WarehouseAfterNextValidationCallbackContext);
    function createWarehouse() {
        return __awaiter(this, void 0, void 0, function* () {
            if (titleBinding.value === "") {
                return;
            }
            let id = null;
            nextValidCallbacks.push(() => {
                warehouseIdBinding.update(id);
            });
            id = yield client.createWarehouse(warehouseDef.type, {
                meta: {
                    title: titleBinding.value
                },
                config: configBinding.value
            });
        });
    }
    return _jsxs(CardWithActions, Object.assign({ title: `创建 ${warehouseDef.title}`, actions: [
            _jsx(ActionButton, Object.assign({ onClick: createWarehouse }, { children: "\u521B\u5EFA" }))
        ] }, { children: [_jsx(FormItem, Object.assign({ label: "\u6807\u9898" }, { children: _jsx(StringField, { binding: titleBinding }) })), _jsx(Divider, {}), _jsx(WarehouseConfigInternalProviderContext.Provider, Object.assign({ value: configBinding }, { children: _jsx(WarehouseMetaInternalProviderContext.Provider, Object.assign({ value: metaBinding }, { children: warehouseDef.render.config() })) }))] }));
}
const WarehouseConfigInternalProviderContext = createNullableContext("useInternalWarehouseConfig is only available in warehouse definition");
export function useInternalWarehouseConfig() {
    return useNullableContext(WarehouseConfigInternalProviderContext);
}
const WarehouseMetaInternalProviderContext = createNullableContext("useInternalWarehouseMeta is only available in warehouse definition");
export function useInternalWarehouseMeta() {
    return useNullableContext(WarehouseMetaInternalProviderContext);
}
export function WarehouseConsumer({ children, warehouse: warehouseInput }) {
    const warehouseDef = useNullableContext(WarehouseDefinitionContext);
    const warehouseId = useNullableContext(WarehouseIdBindingContext);
    if (warehouseDef.type !== warehouseInput.type) {
        throw new Error(`Invalid warehouse consumer for ${warehouseInput.type} in provider for ${warehouseDef.type}`);
    }
    function Internal() {
        if (warehouseId.value === null) {
            throw new Error("Invalid state");
        }
        const metaBindingReq = useWarehouseMetaBinding(warehouseDef.type, warehouseId.value);
        const configBindingReq = useWarehouseConfigBinding(warehouseDef.type, warehouseId.value);
        return unwrapAsyncBinding(metaBindingReq, metaBinding => {
            return unwrapAsyncBinding(configBindingReq, configBinding => {
                return _jsx(_Fragment, { children: children(metaBinding, configBinding) });
            });
        });
    }
    if (warehouseId.value === null) {
        return _jsx(_Fragment, {});
    }
    else {
        return _jsx(Internal, {});
    }
}
