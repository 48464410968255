"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextStyleAndSizePicker = exports.TextStylePicker = exports.getDefaultFontFamily = exports.getFontsList = exports.convertTextStyleToCSS = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var __1 = require("../../");
function convertTextStyleToCSS(textStyle) {
    return {
        color: textStyle.textColor,
        WebkitTextStrokeColor: textStyle.borderColor,
        WebkitTextStrokeWidth: textStyle.borderWidth,
        fontFamily: textStyle.fontFamily,
        fontSize: textStyle.fontSize,
        textAlign: textStyle.alignment
    };
}
exports.convertTextStyleToCSS = convertTextStyleToCSS;
function getFontsList() {
    var fontFamilies = new Set();
    fontFamilies.add("\"Arial\"");
    document.fonts.forEach(function (f) { return fontFamilies.add(f.family); });
    var list = Array.from(fontFamilies);
    list.sort();
    return list;
}
exports.getFontsList = getFontsList;
function getDefaultFontFamily() {
    return getFontsList()[0];
}
exports.getDefaultFontFamily = getDefaultFontFamily;
function useLoadedFontFamilies() {
    return (0, react_1.useMemo)(function () { return getFontsList(); }, []);
}
function removeQuotes(s) {
    return s.replace(/^"/, "").replace(/"$/, "");
}
function FontSelect(props) {
    var fontFamilies = useLoadedFontFamilies();
    var defaultFont = (0, react_1.useMemo)(function () { return getDefaultFontFamily(); }, []);
    var binding = (0, __1.defaultValueBinding)(props.binding, defaultFont);
    return ((0, jsx_runtime_1.jsx)(__1.SelectWithFilter, { placeholder: "\u9009\u62E9\u5B57\u4F53", binding: binding, options: fontFamilies.map(function (family) { return ({
            label: ((0, jsx_runtime_1.jsx)("span", __assign({ style: { fontFamily: family } }, { children: removeQuotes(family) }))),
            value: family
        }); }) }));
}
function SharedTextEdit(props) {
    return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(__1.Grid, __assign({ container: true, span: 12 }, { children: [(0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 3 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u6587\u5B57\u989C\u8272" }, { children: (0, jsx_runtime_1.jsx)(__1.ColorPicker, { binding: (0, __1.propertyBinding)(props.binding, "textColor") }) })) })), (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 1 }, { children: (0, jsx_runtime_1.jsx)("span", {}) })), (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 3 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u8FB9\u7F18\u989C\u8272" }, { children: (0, jsx_runtime_1.jsx)(__1.ColorPicker, { binding: (0, __1.propertyBinding)(props.binding, "borderColor") }) })) })), (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 5 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u8FB9\u7F18\u7C97\u7EC6" }, { children: (0, jsx_runtime_1.jsx)(__1.NumberField, { binding: (0, __1.propertyBinding)(props.binding, "borderWidth"), min: 0, step: 0.25 }) })) }))] })), (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ container: true, span: 12 }, { children: (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 8 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u5BF9\u9F50\u65B9\u5F0F" }, { children: (0, jsx_runtime_1.jsx)(__1.Select, { binding: (0, __1.propertyBinding)(props.binding, "alignment"), options: [
                                { value: "left", label: "左对齐" },
                                { value: "right", label: "右对齐" },
                                { value: "center", label: "居中对齐" },
                            ] }) })) })) }))] });
}
function TextStylePicker(props) {
    return (0, jsx_runtime_1.jsxs)(__1.Grid, __assign({ container: true }, { children: [(0, jsx_runtime_1.jsx)(__1.Grid, __assign({ container: true, span: 12 }, { children: (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 8 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u5B57\u4F53\u7C7B\u578B" }, { children: (0, jsx_runtime_1.jsx)(FontSelect, { binding: (0, __1.propertyBinding)(props.binding, "fontFamily") }) })) })) })), (0, jsx_runtime_1.jsx)(SharedTextEdit, { binding: props.binding })] }));
}
exports.TextStylePicker = TextStylePicker;
function TextStyleAndSizePicker(props) {
    var fontFamily = (0, __1.propertyBinding)(props.binding, "fontFamily");
    var fontSize = (0, __1.propertyBinding)(props.binding, "fontSize");
    var sharedBinding = (0, __1.mapBinding)(props.binding, {
        forward: function (_a) {
            var fontSize = _a.fontSize, rest = __rest(_a, ["fontSize"]);
            return rest;
        },
        backward: function (value, _a) {
            var fontSize = _a.fontSize;
            return (__assign({ fontSize: fontSize }, value));
        }
    });
    return (0, jsx_runtime_1.jsxs)(__1.Grid, __assign({ container: true }, { children: [(0, jsx_runtime_1.jsxs)(__1.Grid, __assign({ container: true, span: 12 }, { children: [(0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 8 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u5B57\u4F53\u7C7B\u578B" }, { children: (0, jsx_runtime_1.jsx)(FontSelect, { binding: fontFamily }) })) })), (0, jsx_runtime_1.jsx)(__1.Grid, __assign({ span: 4 }, { children: (0, jsx_runtime_1.jsx)(__1.FormItem, __assign({ label: "\u5927\u5C0F" }, { children: (0, jsx_runtime_1.jsx)(__1.NumberField, { binding: fontSize, min: 1, step: 1 }) })) }))] })), (0, jsx_runtime_1.jsx)(SharedTextEdit, { binding: sharedBinding })] }));
}
exports.TextStyleAndSizePicker = TextStyleAndSizePicker;
