"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorPicker = exports.Notification = exports.Tooltip = exports.QuickConfirm = exports.Dialog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var react_color_1 = require("react-color");
function Dialog(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ title: props.title, open: props.open, onOk: props.onOk, onCancel: props.onCancel, okButtonProps: { disabled: props.disableOk }, width: "50%" }, { children: props.children }));
}
exports.Dialog = Dialog;
function QuickConfirm(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Popconfirm, __assign({ title: props.title, description: props.description, onConfirm: props.onConfirm }, { children: props.children }));
}
exports.QuickConfirm = QuickConfirm;
function Tooltip(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Tooltip, __assign({ title: props.title }, { children: props.children }));
}
exports.Tooltip = Tooltip;
function Notification(props) {
    var _a = antd_1.notification.useNotification(), api = _a[0], contextHolder = _a[1];
    (0, react_1.useEffect)(function () {
        if (props.binding.value) {
            api.open({
                message: props.title,
                description: props.content,
                icon: props.icon,
                type: props.type,
                onClose: function () {
                    props.binding.update(false);
                }
            });
        }
    }, [props.binding.value, props.title, props.content, props.type]);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: contextHolder });
}
exports.Notification = Notification;
function ColorPicker(props) {
    var content = (0, jsx_runtime_1.jsx)(react_color_1.CompactPicker, { color: props.binding.value, onChange: function (res) { return props.binding.update(res.hex); } });
    return (0, jsx_runtime_1.jsx)(antd_1.Popover, __assign({ title: "\u9009\u62E9\u989C\u8272", content: content, trigger: "click" }, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "default", style: { backgroundColor: props.binding.value, boxShadow: "inset 0px 0px 3px #888" } }) }));
}
exports.ColorPicker = ColorPicker;
