import { jsx as _jsx } from "react/jsx-runtime";
import { createNullableContext, useNullableContext } from "@pltk/components";
import { useMemo } from "react";
const EnabledWidgetContext = createNullableContext("Widgets provider not initialized.");
export function EnabledWidgetProvider(props) {
    const store = useMemo(() => {
        const mapping = {};
        for (const p of props.widgets) {
            mapping[p.type] = p;
        }
        return { list: props.widgets, mapping };
    }, [props.widgets]);
    return _jsx(EnabledWidgetContext.Provider, Object.assign({ value: store }, { children: props.children }));
}
export function useEnabledWidgetList() {
    return useNullableContext(EnabledWidgetContext).list;
}
export function useEnabledWidgets() {
    return useNullableContext(EnabledWidgetContext).mapping;
}
