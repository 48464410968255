var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { arrayBinding, Collapse, convertTextStyleToCSS, Flex, Grid, IconButton, Icons, IconSwitch, propertyBinding, StringField, TextStyleAndSizePicker, useLocalDBinding } from "@pltk/components";
import { WarehouseConsumer, WarehouseEditor, WarehouseProvider, WarehouseSelect, useWidgetConfigInternal } from "@pltk/core";
import "@fontsource/zcool-kuaile";
import { ChecklistWarehouse } from "./ChecklistWarehouse";
const DEFAULT_FONT = '"ZCOOL KuaiLe"';
function textStyle(done, textStyle) {
    if (done) {
        return Object.assign({ opacity: 0.5, textDecoration: "line-through", textDecorationColor: textStyle.borderColor, textDecorationStyle: "solid", textDecorationThickness: 0.1 * textStyle.fontSize, width: "100%", height: "100%" }, convertTextStyleToCSS(textStyle));
    }
    else {
        return Object.assign({ opacity: 1, width: "100%", height: "100%" }, convertTextStyleToCSS(textStyle));
    }
}
export function ChecklistPreview() {
    const configBinding = useWidgetConfigInternal();
    return _jsx(WarehouseProvider, Object.assign({ warehouse: ChecklistWarehouse, binding: propertyBinding(configBinding, "warehouseId") }, { children: _jsx(WarehouseConsumer, Object.assign({ warehouse: ChecklistWarehouse }, { children: (meta, config) => {
                const itemsBinding = arrayBinding(propertyBinding(config, "items"));
                return (_jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: itemsBinding.value.filter(it => it.show).map((item, i) => (_jsx(Grid, Object.assign({ span: 12 }, { children: _jsx("div", Object.assign({ style: textStyle(item.done, configBinding.value.textStyle) }, { children: item.content })) }), i))) })));
            } })) }));
}
function ChecklistDoneButton(props) {
    return _jsx(IconSwitch, { binding: props.binding, size: props.size, enabledIcon: _jsx(Icons.Completed, {}), disabledIcon: _jsx(Icons.Pending, {}) });
}
export function ChecklistEdit() {
    const configBinding = useWidgetConfigInternal();
    const newItemBinding = useLocalDBinding("");
    const warehouseIdBinding = propertyBinding(configBinding, "warehouseId");
    return (_jsx(WarehouseProvider, Object.assign({ binding: warehouseIdBinding, warehouse: ChecklistWarehouse }, { children: _jsx(WarehouseConsumer, Object.assign({ warehouse: ChecklistWarehouse }, { children: (meta, config) => {
                const itemsBinding = arrayBinding(propertyBinding(config, "items"));
                return _jsxs(Flex, Object.assign({ direction: "vertical", nowrap: true }, { children: [itemsBinding.items.map(item => (_jsxs(Flex, Object.assign({ direction: "horizontal", alignment: "space-between", nowrap: true }, { children: [_jsx("div", Object.assign({ style: textStyle(item.value.done, configBinding.value.textStyle) }, { children: item.value.content })), _jsx(ChecklistDoneButton, { binding: propertyBinding(item, "done"), size: "large" })] })))), _jsxs(Flex, Object.assign({ direction: "horizontal", alignment: "space-between", nowrap: true }, { children: [_jsx(StringField, { binding: newItemBinding }), _jsx(IconButton, { onClick: () => __awaiter(this, void 0, void 0, function* () {
                                        if (newItemBinding.value === "")
                                            return;
                                        yield itemsBinding.append({ done: false, content: newItemBinding.value, show: true });
                                        newItemBinding.update("");
                                    }), icon: _jsx(Icons.Add, {}) })] }))] }));
            } })) })));
}
export function ChecklistConfigPanel() {
    const configBinding = useWidgetConfigInternal();
    const textStyle = propertyBinding(configBinding, "textStyle");
    const warehouseIdBinding = propertyBinding(configBinding, "warehouseId");
    return _jsxs(Flex, Object.assign({ direction: "vertical", nowrap: true, spacing: 8 }, { children: [_jsxs(WarehouseProvider, Object.assign({ binding: warehouseIdBinding, warehouse: ChecklistWarehouse }, { children: [_jsx(WarehouseSelect, {}), _jsx(WarehouseEditor, {})] })), _jsx(Collapse, Object.assign({ title: "\u5B57\u4F53\u8BBE\u7F6E" }, { children: _jsx(TextStyleAndSizePicker, { binding: textStyle }) }))] }));
}
export const ChecklistPlugin = {
    title: "待办清单",
    type: "builtin.checklist",
    initialize: {
        defaultSize: () => ({ width: 300, height: 600 }),
        defaultConfig: () => ({
            textStyle: {
                borderColor: "#333333",
                borderWidth: 1.25,
                fontFamily: DEFAULT_FONT,
                textColor: "white",
                fontSize: 35,
                alignment: "left"
            },
            warehouseId: null
        })
    },
    render: {
        edit: () => _jsx(ChecklistEdit, {}),
        preview: () => _jsx(ChecklistPreview, {}),
        move: () => _jsx(ChecklistPreview, {}),
        config: () => _jsx(ChecklistConfigPanel, {})
    }
};
export default ChecklistPlugin;
