import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { CacheStore } from "./cache";
import { createNullableContext, useNullableContext } from "@pltk/components";
const ClientContext = createNullableContext("Backend not initialized");
const FileStorageContext = createNullableContext("FileStorage not initialized");
const SubscriptionContext = createNullableContext("Subscription not initialized");
const CacheContext = createNullableContext("Cache not initialized");
export function BackendProvider(props) {
    const cache = useMemo(() => {
        return new CacheStore(props.client, props.subscription);
    }, [props.client, props.subscription]);
    return _jsx(FileStorageContext.Provider, Object.assign({ value: props.fileStorage }, { children: _jsx(SubscriptionContext.Provider, Object.assign({ value: props.subscription }, { children: _jsx(ClientContext.Provider, Object.assign({ value: props.client }, { children: _jsx(CacheContext.Provider, Object.assign({ value: cache }, { children: props.children })) })) })) }));
}
export function useLiveToolkitClient() {
    return useNullableContext(ClientContext);
}
export function useLiveToolkitSubscription() {
    return useNullableContext(SubscriptionContext);
}
export function useLiveToolkitFileStorage() {
    return useNullableContext(FileStorageContext);
}
export function useAPISubscriptionCache() {
    return useNullableContext(CacheContext);
}
const PanelIdContext = createNullableContext("Panel ID not provided");
export const PanelIdProvider = PanelIdContext.Provider;
export function usePanelId() {
    return useNullableContext(PanelIdContext);
}
const WidgetReferenceContext = createNullableContext("Widget Meta not provided");
export const WidgetProvider = WidgetReferenceContext.Provider;
export function useWidgetId() {
    return useNullableContext(WidgetReferenceContext).id;
}
export function useWidgetMeta() {
    return useNullableContext(WidgetReferenceContext).meta;
}
