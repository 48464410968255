"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loading = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var icons_1 = require("@ant-design/icons");
var antd_1 = require("antd");
function Loading() {
    var loadingIcon = (0, jsx_runtime_1.jsx)(icons_1.LoadingOutlined, { style: { fontSize: 24 }, spin: true });
    return (0, jsx_runtime_1.jsx)(antd_1.Spin, { indicator: loadingIcon });
}
exports.Loading = Loading;
