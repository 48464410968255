import { jsx as _jsx } from "react/jsx-runtime";
import { PreviewWidget } from "../components/widgets";
import { PanelIdProvider, usePanel, usePanelId, useWidgetListOfPanel, WidgetProvider } from "@pltk/core";
import { usePanelIdFromParams } from "./utils";
import { PanelSizeContext } from "../components/context";
import { unwrapAsyncSubs } from "@pltk/components";
function PanelExhibitionBody() {
    const panelId = usePanelId();
    const panelReq = usePanel(panelId);
    const widgetsReq = useWidgetListOfPanel(panelId);
    return unwrapAsyncSubs(panelReq, panel => {
        const panelSize = {
            scale: 1,
            configSize: panel.size,
            actualRect: Object.assign({ x: 0, y: 0 }, panel.size)
        };
        return unwrapAsyncSubs(widgetsReq, widgets => (_jsx("div", Object.assign({ style: panel.size }, { children: _jsx(PanelSizeContext.Provider, Object.assign({ value: panelSize }, { children: widgets.map(widget => (_jsx(WidgetProvider, Object.assign({ value: widget }, { children: _jsx(PreviewWidget, { widget: widget }) }), widget.id))) })) }))));
    });
}
export function PanelExhibitionPage() {
    const panelId = usePanelIdFromParams();
    return _jsx(PanelIdProvider, Object.assign({ value: panelId }, { children: _jsx(PanelExhibitionBody, {}) }));
}
