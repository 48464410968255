"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createKVBackend = exports.KVFileStorage = exports.KVSubscription = exports.KVDataClient = void 0;
var api_1 = require("./api");
var KVDataClient = /** @class */ (function () {
    function KVDataClient(clientFactory) {
        this.api = new api_1.APIWrapper(clientFactory);
    }
    KVDataClient.prototype.getPanels = function () {
        return __awaiter(this, void 0, void 0, function () {
            var globalClient;
            return __generator(this, function (_a) {
                globalClient = new api_1.GlobalClient(this.api);
                return [2 /*return*/, globalClient.panels()];
            });
        });
    };
    KVDataClient.prototype.getPanel = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var panelClient, _a, meta, size;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        panelClient = new api_1.PanelClient(this.api, id);
                        return [4 /*yield*/, Promise.all([
                                panelClient.meta(),
                                panelClient.size()
                            ])];
                    case 1:
                        _a = _b.sent(), meta = _a[0], size = _a[1];
                        return [2 /*return*/, { meta: meta, size: size }];
                }
            });
        });
    };
    KVDataClient.prototype.createPanel = function (panel) {
        return __awaiter(this, void 0, void 0, function () {
            var globalClient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        globalClient = new api_1.GlobalClient(this.api);
                        return [4 /*yield*/, globalClient.createPanel(panel.meta, panel.size)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.deletePanel = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var panelClient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        panelClient = new api_1.PanelClient(this.api, id);
                        return [4 /*yield*/, panelClient.delete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.setPanelMeta = function (id, meta) {
        return __awaiter(this, void 0, void 0, function () {
            var panelClient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        panelClient = new api_1.PanelClient(this.api, id);
                        return [4 /*yield*/, panelClient.setMeta(meta)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.setPanelSize = function (id, size) {
        return __awaiter(this, void 0, void 0, function () {
            var panelClient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        panelClient = new api_1.PanelClient(this.api, id);
                        return [4 /*yield*/, panelClient.resize(size)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.getWidgetsOfPanel = function (panelId) {
        return __awaiter(this, void 0, void 0, function () {
            var client, pluginIdList;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PanelClient(this.api, panelId);
                        return [4 /*yield*/, client.pluginsList()];
                    case 1:
                        pluginIdList = _a.sent();
                        return [2 /*return*/, Promise.all(pluginIdList.map(function (id) { return __awaiter(_this, void 0, void 0, function () {
                                var pluginClient, meta;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            pluginClient = new api_1.PluginClient(this.api, panelId, id);
                                            return [4 /*yield*/, pluginClient.meta()];
                                        case 1:
                                            meta = _a.sent();
                                            return [2 /*return*/, { id: id, meta: meta }];
                                    }
                                });
                            }); }))];
                }
            });
        });
    };
    KVDataClient.prototype.getWidgetMeta = function (panelId, widgetId) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.meta()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.getWidgetRect = function (panelId, widgetId) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.size()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.getWidgetConfig = function (panelId, widgetId) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.config()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.createWidget = function (panelId, widget) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PanelClient(this.api, panelId);
                        return [4 /*yield*/, client.createPlugin(widget.meta.type, widget.rect, widget.config)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.deleteWidget = function (panelId, widgetId) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.delete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.setWidgetMeta = function (panelId, widgetId, meta) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.setMeta(meta)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.setWidgetRect = function (panelId, widgetId, rect) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.resize(rect)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.setWidgetConfig = function (panelId, widgetId, config) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.PluginClient(this.api, panelId, widgetId);
                        return [4 /*yield*/, client.setConfig(config)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KVDataClient.prototype.getWarehouseList = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.list(type)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.getWarehouse = function (type, id) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.get(type, id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.createWarehouse = function (type, warehouse) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.create(type, warehouse)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.getWarehouseMeta = function (type, id) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.getMeta(type, id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.getWarehouseConfig = function (type, id) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.getConfig(type, id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.setWarehouseMeta = function (type, id, meta) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.setMeta(type, id, meta)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.setWarehouseConfig = function (type, id, config) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.setConfig(type, id, config)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    KVDataClient.prototype.deleteWarehouse = function (type, id) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        client = new api_1.WarehouseClient(this.api);
                        return [4 /*yield*/, client.delete(type, id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return KVDataClient;
}());
exports.KVDataClient = KVDataClient;
var KVSubscription = /** @class */ (function () {
    function KVSubscription(subscriptionFactory) {
        this.subs = new api_1.SubscriptionWrapper(subscriptionFactory);
    }
    KVSubscription.prototype.subscribePanels = function (callback) {
        return this.subs.subscribePanels(callback);
    };
    KVSubscription.prototype.subscribePanel = function (id, callback) {
        var meta = this.subs.subscribePanelMeta(id, callback);
        var size = this.subs.subscribePanelSize(id, callback);
        return {
            close: function () {
                meta.close();
                size.close();
            }
        };
    };
    KVSubscription.prototype.subscribeWidgetsOfPanel = function (panelId, callback) {
        return this.subs.subscribePlugins(panelId, callback);
    };
    KVSubscription.prototype.subscribeWidgetRect = function (panelId, widgetId, callback) {
        return this.subs.subscribePluginSize(panelId, widgetId, callback);
    };
    KVSubscription.prototype.subscribeWidgetConfig = function (panelId, widgetId, callback) {
        return this.subs.subscribePluginConfig(panelId, widgetId, callback);
    };
    KVSubscription.prototype.subscribeWarehouseList = function (type, cb) {
        return this.subs.subscribeWarehouseList(type, cb);
    };
    KVSubscription.prototype.subscribeWarehouseMeta = function (type, id, cb) {
        return this.subs.subscribeWarehouseMeta(type, id, cb);
    };
    KVSubscription.prototype.subscribeWarehouseConfig = function (type, id, cb) {
        return this.subs.subscribeWarehouseConfig(type, id, cb);
    };
    KVSubscription.prototype.subscribe = function (evt, cb) {
        if (evt.type === "PanelList") {
            return this.subscribePanels(cb);
        }
        else if (evt.type === "Panel") {
            return this.subscribePanel(evt.parameters.panelId, cb);
        }
        else if (evt.type === "WidgetListOfPanel") {
            return this.subscribeWidgetsOfPanel(evt.parameters.panelId, cb);
        }
        else if (evt.type === "WidgetRect") {
            return this.subscribeWidgetRect(evt.parameters.panelId, evt.parameters.widgetId, cb);
        }
        else if (evt.type === "WidgetConfig") {
            return this.subscribeWidgetConfig(evt.parameters.panelId, evt.parameters.widgetId, cb);
        }
        else if (evt.type === "WarehouseList") {
            return this.subscribeWarehouseList(evt.parameters.warehouseType, cb);
        }
        else if (evt.type === "WarehouseMeta") {
            return this.subscribeWarehouseMeta(evt.parameters.warehouseType, evt.parameters.warehouseId, cb);
        }
        else if (evt.type === "WarehouseConfig") {
            return this.subscribeWarehouseConfig(evt.parameters.warehouseType, evt.parameters.warehouseId, cb);
        }
        else {
            throw new Error("Unknown event: " + JSON.stringify(evt));
        }
    };
    return KVSubscription;
}());
exports.KVSubscription = KVSubscription;
var KVFileStorage = /** @class */ (function () {
    function KVFileStorage(client) {
        this.client = client;
    }
    KVFileStorage.prototype.create = function (data) {
        return this.client.create(data);
    };
    KVFileStorage.prototype.fetch = function (id) {
        return this.client.read(id);
    };
    KVFileStorage.prototype.update = function (id, data) {
        return this.client.update(id, data);
    };
    KVFileStorage.prototype.delete = function (id) {
        return this.client.delete(id);
    };
    return KVFileStorage;
}());
exports.KVFileStorage = KVFileStorage;
function createKVBackend(options) {
    return {
        client: new KVDataClient(options.clientFactory),
        subscription: new KVSubscription(options.subscriptionFactory),
        fileStorage: new KVFileStorage(options.files)
    };
}
exports.createKVBackend = createKVBackend;
