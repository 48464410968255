"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNullableContext = exports.createNullableContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
function createNullableContext(message) {
    var context = (0, react_1.createContext)({ initialized: false, message: message });
    return {
        context: context,
        Provider: function (props) { return ((0, jsx_runtime_1.jsx)(context.Provider, __assign({ value: { initialized: true, value: props.value } }, { children: props.children }))); }
    };
}
exports.createNullableContext = createNullableContext;
function useNullableContext(context) {
    var internal = (0, react_1.useContext)(context.context);
    if (internal.initialized) {
        return internal.value;
    }
    else {
        throw new Error(internal['message']);
    }
}
exports.useNullableContext = useNullableContext;
