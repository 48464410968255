"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearIndexedDBBackendData = exports.createIndexedDBBackend = exports.IndexedDBFileClient = exports.IndexedDBSubscriptionDataClient = exports.IndexedDBDataClient = void 0;
var idb = __importStar(require("idb"));
var kv_backend_adaptor_1 = require("@pltk/kv-backend-adaptor");
var BROADCAST_CHANNEL_NAME = "dualise.mock.notification";
var DBWrapper;
(function (DBWrapper) {
    var IndexedDBName = "dualies.mock.db:data_kv";
    var DataStoreName = "data_kv";
    var FileStoreName = "file_kv";
    function openInternal(dbName) {
        return __awaiter(this, void 0, void 0, function () {
            var db;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idb.openDB(dbName, 1, {
                            upgrade: function (db) {
                                db.createObjectStore(DataStoreName);
                                db.createObjectStore(FileStoreName);
                            }
                        })];
                    case 1:
                        db = _a.sent();
                        return [2 /*return*/, db];
                }
            });
        });
    }
    function getInternal(dbName, storeName, key) {
        return __awaiter(this, void 0, void 0, function () {
            var db, tx, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, openInternal(dbName)];
                    case 1:
                        db = _a.sent();
                        tx = db.transaction(storeName, "readonly", {});
                        return [4 /*yield*/, tx.store.get(key)];
                    case 2:
                        value = _a.sent();
                        return [4 /*yield*/, tx.done];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, value !== null && value !== void 0 ? value : null];
                }
            });
        });
    }
    function putInternal(dbName, storeName, key, value) {
        return __awaiter(this, void 0, void 0, function () {
            var db, tx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, openInternal(dbName)];
                    case 1:
                        db = _a.sent();
                        tx = db.transaction(storeName, "readwrite", {});
                        return [4 /*yield*/, tx.store.put(value, key)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, tx.done];
                    case 3:
                        _a.sent();
                        db.close();
                        return [2 /*return*/];
                }
            });
        });
    }
    function deleteInternal(dbName, storeName, key) {
        return __awaiter(this, void 0, void 0, function () {
            var db, tx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, openInternal(dbName)];
                    case 1:
                        db = _a.sent();
                        tx = db.transaction(storeName, "readwrite");
                        return [4 /*yield*/, tx.store.delete(key)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, tx.done];
                    case 3:
                        _a.sent();
                        db.close();
                        return [2 /*return*/];
                }
            });
        });
    }
    var DBAccess = /** @class */ (function () {
        function DBAccess(dbName, storeName, key) {
            this.dbName = dbName;
            this.storeName = storeName;
            this.key = key;
        }
        DBAccess.prototype.get = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getInternal(this.dbName, this.storeName, this.key)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        };
        DBAccess.prototype.set = function (value) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, putInternal(this.dbName, this.storeName, this.key, value)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        DBAccess.prototype.delete = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, deleteInternal(this.dbName, this.storeName, this.key)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        return DBAccess;
    }());
    function data(key) {
        return new DBAccess(IndexedDBName, DataStoreName, key);
    }
    DBWrapper.data = data;
    function file(key) {
        return new DBAccess(IndexedDBName, FileStoreName, key);
    }
    DBWrapper.file = file;
    function destroyDB() {
        return idb.deleteDB(IndexedDBName);
    }
    DBWrapper.destroyDB = destroyDB;
})(DBWrapper || (DBWrapper = {}));
var IndexedDBDataClient = /** @class */ (function () {
    function IndexedDBDataClient(key) {
        this.key = key;
    }
    IndexedDBDataClient.prototype.broadcastMessage = function () {
        var bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
        bc.postMessage(this.key);
        bc.close();
    };
    Object.defineProperty(IndexedDBDataClient.prototype, "db", {
        get: function () {
            return DBWrapper.data(this.key);
        },
        enumerable: false,
        configurable: true
    });
    IndexedDBDataClient.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("GET", this.key);
                        return [4 /*yield*/, this.db.get()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    IndexedDBDataClient.prototype.set = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("SET", this.key);
                        return [4 /*yield*/, this.db.set(data)];
                    case 1:
                        _a.sent();
                        this.broadcastMessage();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndexedDBDataClient.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("DEL", this.key);
                        return [4 /*yield*/, this.db.delete()];
                    case 1:
                        _a.sent();
                        this.broadcastMessage();
                        return [2 /*return*/];
                }
            });
        });
    };
    return IndexedDBDataClient;
}());
exports.IndexedDBDataClient = IndexedDBDataClient;
var IndexedDBSubscriptionDataClient = /** @class */ (function () {
    function IndexedDBSubscriptionDataClient(key) {
        this.key = key;
    }
    IndexedDBSubscriptionDataClient.prototype.subscribe = function (callback) {
        var _this = this;
        var bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
        bc.onmessage = function (message) {
            var key = message.data;
            console.log("SUB", key);
            if (key === _this.key) {
                callback();
            }
        };
        return {
            close: function () { return bc.close(); }
        };
    };
    return IndexedDBSubscriptionDataClient;
}());
exports.IndexedDBSubscriptionDataClient = IndexedDBSubscriptionDataClient;
var IndexedDBFileClient = /** @class */ (function () {
    function IndexedDBFileClient() {
    }
    IndexedDBFileClient.prototype.create = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var uuid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uuid = crypto.randomUUID();
                        return [4 /*yield*/, this.update(uuid, data)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, uuid];
                }
            });
        });
    };
    IndexedDBFileClient.prototype.db = function (path) {
        return DBWrapper.file(path);
    };
    IndexedDBFileClient.prototype.update = function (id, data) {
        return __awaiter(this, void 0, void 0, function () {
            var raw;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("FILE WRITE", id);
                        raw = typeof data === "string" ?
                            new Blob([new TextEncoder().encode(data)]) :
                            data;
                        return [4 /*yield*/, this.db(id).set(raw)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndexedDBFileClient.prototype.read = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("FILE READ", id);
                        return [4 /*yield*/, this.db(id).get()];
                    case 1:
                        data = _a.sent();
                        if (data === null) {
                            throw new Error("Not found: ".concat(data));
                        }
                        return [2 /*return*/, data];
                }
            });
        });
    };
    IndexedDBFileClient.prototype.delete = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.db(id).delete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return IndexedDBFileClient;
}());
exports.IndexedDBFileClient = IndexedDBFileClient;
function createIndexedDBBackend() {
    return (0, kv_backend_adaptor_1.createKVBackend)({
        clientFactory: function (key) { return new IndexedDBDataClient(key); },
        subscriptionFactory: function (key) { return new IndexedDBSubscriptionDataClient(key); },
        files: new IndexedDBFileClient()
    });
}
exports.createIndexedDBBackend = createIndexedDBBackend;
function clearIndexedDBBackendData() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, DBWrapper.destroyDB()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.clearIndexedDBBackendData = clearIndexedDBBackendData;
