var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionButton, Dialog, Flex, FormItem, HStack, Icons, Select, Switch, useLocalDBinding, CopyableInput, IconButton, Button, StringField, LiteDangerButton, QuickConfirm, defaultValueBinding, NumberField, propertyBinding, unwrapAsyncSubs, useNullableContext, createNullableContext, Collapse } from "@pltk/components";
import { PanelIdProvider, useLiveToolkitClient, usePanel, usePanelId, useWidgetListOfPanel, WidgetConfigInternalProvider, WidgetProvider } from "@pltk/core";
import { TransparentBackground } from "../components/backgrounds";
import { PreviewModeContext } from "../components/context";
import { KeepRatio } from "../components/panels/KeepRatio";
import { EditableWidget } from "../components/widgets";
import "./Panel.css";
import { usePanelIdFromParams } from "./utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEnabledWidgetList, useEnabledWidgets } from "@pltk/core";
function ShareButton() {
    return _jsx(FormItem, Object.assign({ label: "\u5C55\u793A\u9875\u94FE\u63A5" }, { children: _jsx(CopyableInput, { value: `${window.location.href}/exhibition`, successMessage: "\u5DF2\u590D\u5236\u5C55\u793A\u9875\u94FE\u63A5" }) }));
}
function AddPluginButton() {
    const showDialog = useLocalDBinding(false);
    return _jsxs(_Fragment, { children: [_jsx(ActionButton, Object.assign({ icon: _jsx(Icons.Add, {}), onClick: () => showDialog.update(true) }, { children: "\u6DFB\u52A0\u7EC4\u4EF6" })), _jsx(AddPluginDialogInternal, { showDialog: showDialog })] });
}
function AddPluginDialogInternal(props) {
    const panelId = usePanelId();
    const client = useLiveToolkitClient();
    const enabledWidgetsList = useEnabledWidgetList();
    const enabledWidgets = useEnabledWidgets();
    const currentWidgetType = useLocalDBinding(enabledWidgetsList[0].type);
    const tmpConfig = useLocalDBinding(undefined);
    useEffect(() => {
        if (props.showDialog.value) {
            const w = enabledWidgetsList[0];
            currentWidgetType.update(w.type);
            tmpConfig.update(w.initialize.defaultConfig());
        }
    }, [props.showDialog.value]);
    useEffect(() => {
        const w = enabledWidgets[currentWidgetType.value];
        tmpConfig.update(w.initialize.defaultConfig());
    }, [currentWidgetType.value]);
    function create() {
        return __awaiter(this, void 0, void 0, function* () {
            const widget = enabledWidgets[currentWidgetType.value];
            yield client.createWidget(panelId, {
                meta: { type: widget.type },
                rect: Object.assign({ x: 0, y: 0 }, widget.initialize.defaultSize()),
                config: tmpConfig.value
            });
            yield props.showDialog.update(false);
        });
    }
    const currentWidget = enabledWidgets[currentWidgetType.value];
    return _jsxs(Dialog, Object.assign({ title: "\u6DFB\u52A0\u7EC4\u4EF6", open: props.showDialog.value, onOk: create, onCancel: () => props.showDialog.update(false) }, { children: [_jsx(FormItem, Object.assign({ label: "\u7EC4\u4EF6\u7C7B\u578B" }, { children: _jsx(Select, { binding: currentWidgetType, options: enabledWidgetsList.map(plugin => ({
                        value: plugin.type,
                        label: plugin.title
                    })) }) })), _jsx(Collapse, Object.assign({ title: `设置 ${currentWidget.title}` }, { children: _jsx(WidgetConfigInternalProvider, Object.assign({ configBinding: tmpConfig }, { children: currentWidget.render.config() })) }))] }));
}
function IconBtn(props) {
    return _jsx(IconButton, Object.assign({ size: "large" }, props));
}
function PanelTitle() {
    const navigate = useNavigate();
    const panelId = usePanelId();
    const client = useLiveToolkitClient();
    const panel = useNullableContext(PanelInfoContext);
    const [newTitle, setNewTitle] = useState(null);
    if (newTitle === null) {
        return _jsxs(_Fragment, { children: [_jsx(IconBtn, { icon: _jsx(Icons.Home, {}), onClick: () => navigate("/") }), _jsx("div", Object.assign({ style: { fontSize: "1.5rem" } }, { children: panel.meta.title })), _jsx(IconBtn, { icon: _jsx(Icons.Edit, {}), onClick: () => setNewTitle(panel.meta.title) })] });
    }
    else {
        const binding = { value: newTitle, update: (s) => __awaiter(this, void 0, void 0, function* () { return setNewTitle(s); }) };
        return _jsxs(_Fragment, { children: [_jsx(StringField, { binding: binding }), _jsx(IconBtn, { icon: _jsx(Icons.Save, {}), onClick: () => __awaiter(this, void 0, void 0, function* () {
                        yield client.setPanelMeta(panelId, Object.assign(Object.assign({}, panel.meta), { title: newTitle }));
                        setNewTitle(null);
                    }) }), _jsx(IconBtn, { icon: _jsx(Icons.Close, {}), onClick: () => setNewTitle(null) })] });
    }
}
function ResizePanel() {
    const panelId = usePanelId();
    const panel = useNullableContext(PanelInfoContext);
    const client = useLiveToolkitClient();
    const newSizeBinding = useLocalDBinding(null);
    const notNullSize = defaultValueBinding(newSizeBinding, { width: 0, height: 1 });
    return _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ icon: _jsx(Icons.Move, {}), onClick: () => newSizeBinding.update(panel.size) }, { children: "\u8BBE\u7F6E\u9762\u677F\u5C3A\u5BF8" })), _jsx(Dialog, Object.assign({ title: "\u8BBE\u7F6E\u9762\u677F\u5C3A\u5BF8", open: newSizeBinding.value !== null, onOk: () => __awaiter(this, void 0, void 0, function* () {
                    if (newSizeBinding.value !== null) {
                        yield client.setPanelSize(panelId, newSizeBinding.value);
                        yield newSizeBinding.update(null);
                    }
                }), onCancel: () => newSizeBinding.update(null) }, { children: _jsxs(Flex, Object.assign({ direction: "horizontal", alignment: "center", spacing: 20 }, { children: [_jsx(FormItem, Object.assign({ label: "\u5BBD\u5EA6" }, { children: _jsx(NumberField, { binding: propertyBinding(notNullSize, "width") }) })), _jsx(FormItem, Object.assign({ label: "\u9AD8\u5EA6" }, { children: _jsx(NumberField, { binding: propertyBinding(notNullSize, "height") }) }))] })) }))] });
}
function DeletePanelButton() {
    const navigate = useNavigate();
    const panelId = usePanelId();
    const client = useLiveToolkitClient();
    function onDelete() {
        return __awaiter(this, void 0, void 0, function* () {
            yield client.deletePanel(panelId);
            navigate("/");
        });
    }
    return _jsx(QuickConfirm, Object.assign({ title: "\u5220\u9664\u9762\u677F", description: "\u5220\u9664\u540E\u65E0\u6CD5\u6062\u590D", onConfirm: onDelete }, { children: _jsx(LiteDangerButton, Object.assign({ icon: _jsx(Icons.Delete, {}) }, { children: "\u5220\u9664\u9762\u677F" })) }));
}
function PanelInfo() {
    return _jsxs(Flex, Object.assign({ direction: "vertical", alignment: "start", nowrap: true, spacing: 10 }, { children: [_jsx(Flex, Object.assign({ direction: "horizontal", alignment: "start", nowrap: true, spacing: 5 }, { children: _jsx(PanelTitle, {}) })), _jsxs(Flex, Object.assign({ direction: "horizontal", alignment: "start", nowrap: true, spacing: 5 }, { children: [_jsx(ResizePanel, {}), _jsx(DeletePanelButton, {})] }))] }));
}
const PanelInfoContext = createNullableContext("Panel meta not initialized");
function WidgetsPanel() {
    const previewModeBinding = useNullableContext(PreviewModeBindingContext);
    return (_jsxs(Flex, Object.assign({ direction: "vertical", spacing: 16 }, { children: [_jsx(ShareButton, {}), _jsxs(Flex, Object.assign({ direction: "horizontal", alignment: "end", spacing: 20, nowrap: true }, { children: [_jsx(AddPluginButton, {}), _jsx(FormItem, Object.assign({ label: "\u9884\u89C8\u6A21\u5F0F" }, { children: _jsx(Switch, { binding: previewModeBinding }) }))] }))] })));
}
function PanelHeader() {
    const panelId = usePanelId();
    const panelReq = usePanel(panelId);
    return (_jsxs(HStack, Object.assign({ layout: ["1fr", "auto"], className: "route-panel-header", spacing: 20 }, { children: [unwrapAsyncSubs(panelReq, panel => (_jsx(PanelInfoContext.Provider, Object.assign({ value: panel }, { children: _jsx(PanelInfo, {}) })))), _jsx(WidgetsPanel, {})] })));
}
function WidgetContainer() {
    const panelId = usePanelId();
    const panelReq = usePanel(panelId);
    const widgetsReq = useWidgetListOfPanel(panelId);
    return unwrapAsyncSubs(panelReq, panel => {
        return unwrapAsyncSubs(widgetsReq, widgets => {
            return _jsx("div", Object.assign({ className: "route-panel-body" }, { children: _jsxs(KeepRatio, Object.assign({ internalSize: panel.size }, { children: [_jsx(TransparentBackground, {}), _jsx(_Fragment, { children: widgets.map(widget => (_jsx(WidgetProvider, Object.assign({ value: widget }, { children: _jsx(EditableWidget, { widget: widget }) }), widget.id))) })] })) }));
        });
    });
}
const PreviewModeBindingContext = createNullableContext("Preview mode binding not initialized.");
export const PanelPage = () => {
    const panelId = usePanelIdFromParams();
    const previewModeBinding = useLocalDBinding(false);
    return _jsx("div", Object.assign({ className: "route-panel-root" }, { children: _jsxs(PanelIdProvider, Object.assign({ value: panelId }, { children: [_jsx(PreviewModeBindingContext.Provider, Object.assign({ value: previewModeBinding }, { children: _jsx(PanelHeader, {}) })), _jsx(PreviewModeContext.Provider, Object.assign({ value: previewModeBinding.value }, { children: _jsx(WidgetContainer, {}) }))] })) }));
};
