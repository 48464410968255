var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { io } from "socket.io-client";
import { SubscriptionActionType } from "@pltk/protocol";
export class RestClient {
    path(parts) {
        const cleanParts = parts.map(p => {
            if (typeof p === "number") {
                return p.toString();
            }
            const m = p.match(/^\/?(.*?)\/?$/);
            return m === null ? "" : m[1];
        }).filter(s => s.length > 0).join("/");
        return "/api/data/" + cleanParts;
    }
    parseResponse(res) {
        return __awaiter(this, void 0, void 0, function* () {
            if (res.status !== 200) {
                const message = yield res.text();
                throw new Error(message);
            }
            return yield res.json();
        });
    }
    post(path, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(this.path(path), {
                body: JSON.stringify(data),
                method: "POST",
                headers: new Headers({ 'content-type': 'application/json' })
            });
            return this.parseResponse(res);
        });
    }
    get(path) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(this.path(path));
            return this.parseResponse(res);
        });
    }
    put(path, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(this.path(path), {
                body: JSON.stringify(data),
                method: "PUT",
                headers: new Headers({ 'content-type': 'application/json' })
            });
            return this.parseResponse(res);
        });
    }
    del(path) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(this.path(path), {
                method: "DELETE"
            });
            return this.parseResponse(res);
        });
    }
    getPanels() {
        return this.get(["panels"]);
    }
    getPanel(id) {
        return this.get(["panel", id]);
    }
    createPanel(panel) {
        return this.post(["panels"], panel);
    }
    deletePanel(id) {
        return this.del(["panel", id]);
    }
    setPanelMeta(id, meta) {
        return this.put(["panel", id, "meta"], meta);
    }
    setPanelSize(id, size) {
        return this.put(["panel", id, "size"], size);
    }
    getWidgetsOfPanel(panelId) {
        return this.get(["panel", panelId, "widgets"]);
    }
    getWidgetMeta(panelId, widgetId) {
        return this.get(["panel", panelId, "widget", widgetId, "meta"]);
    }
    getWidgetRect(panelId, widgetId) {
        return this.get(["panel", panelId, "widget", widgetId, "rect"]);
    }
    getWidgetConfig(panelId, widgetId) {
        return this.get(["panel", panelId, "widget", widgetId, "config"]);
    }
    createWidget(panelId, widget) {
        return this.post(["panel", panelId, "widgets"], widget);
    }
    deleteWidget(panelId, widgetId) {
        return this.del(["panel", panelId, "widget", widgetId]);
    }
    setWidgetMeta(panelId, widgetId, meta) {
        return this.put(["panel", panelId, "widget", widgetId, "meta"], meta);
    }
    setWidgetRect(panelId, widgetId, rect) {
        return this.put(["panel", panelId, "widget", widgetId, "rect"], rect);
    }
    setWidgetConfig(panelId, widgetId, config) {
        return this.put(["panel", panelId, "widget", widgetId, "config"], config);
    }
    getWarehouseList(type) {
        return this.get(["warehouses", type]);
    }
    getWarehouse(type, id) {
        return this.get(["warehouse", type, id]);
    }
    createWarehouse(type, warehouse) {
        return this.post(["warehouses", type], warehouse);
    }
    getWarehouseMeta(type, id) {
        return this.get(["warehouse", type, id, "meta"]);
    }
    getWarehouseConfig(type, id) {
        return this.get(["warehouse", type, id, "config"]);
    }
    setWarehouseMeta(type, id, meta) {
        return this.put(["warehouse", type, id, "meta"], meta);
    }
    setWarehouseConfig(type, id, config) {
        return this.put(["warehouse", type, id, "config"], config);
    }
    deleteWarehouse(type, id) {
        return this.del(["warehouse", type, id]);
    }
}
export class RestSubscription {
    constructor() {
        this.idCounter = 0;
        this.listeners = new Map();
        this.socket = io({
            path: "/api/subscription"
        });
        this.socket.on(SubscriptionActionType.Update, (evt) => {
            this.emitHub(evt);
        });
    }
    subscribe(evt, callback) {
        return this.subscribeHub(evt, callback);
    }
    stringifyEvent(evt) {
        const parts = [evt.type];
        if ("panelId" in evt.parameters) {
            parts.push(`panelId=${evt.parameters.panelId}`);
        }
        if ("widgetId" in evt.parameters) {
            parts.push(`widgetId=${evt.parameters.widgetId}`);
        }
        if ("warehouseType" in evt.parameters) {
            parts.push(`warehouseType=${evt.parameters.warehouseType}`);
        }
        if ("warehouseId" in evt.parameters) {
            parts.push(`warehouseId=${evt.parameters.warehouseId}`);
        }
        return parts.join("_");
    }
    registerCallback(key, id, callback) {
        let c = this.listeners.get(key);
        let subscribe = false;
        if (!c) {
            c = new Map();
            this.listeners.set(key, c);
            subscribe = true;
        }
        c.set(id, callback);
        return subscribe;
    }
    disposeCallback(key, id) {
        const c = this.listeners.get(key);
        let dispose = false;
        if (!c)
            return false;
        c.delete(id);
        if (c.size === 0) {
            this.listeners.delete(key);
            dispose = true;
        }
        return dispose;
    }
    subscribeHub(evt, callback) {
        const id = this.idCounter++;
        const eventKey = this.stringifyEvent(evt);
        const subscribe = this.registerCallback(eventKey, id, callback);
        if (subscribe) {
            this.socket.emit(SubscriptionActionType.Subscribe, evt);
        }
        return {
            close: () => {
                const dispose = this.disposeCallback(eventKey, id);
                if (dispose) {
                    this.socket.emit(SubscriptionActionType.Dispose, evt);
                }
            }
        };
    }
    emitHub(evt) {
        var _a;
        const key = this.stringifyEvent(evt);
        const callbacks = (_a = this.listeners.get(key)) === null || _a === void 0 ? void 0 : _a.values();
        if (!callbacks)
            return;
        for (const cb of callbacks) {
            setTimeout(cb, 0);
        }
    }
}
export class RestFileStorage {
    create(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch("/api/files", {
                method: "POST",
                body: data
            });
            if (res.status !== 200) {
                throw new Error(yield res.text());
            }
            return res.text();
        });
    }
    fetch(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`/api/files/${id}`);
            if (res.status !== 200) {
                throw new Error(yield res.text());
            }
            return res.blob();
        });
    }
    update(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`/api/files/${id}`, { method: "PUT" });
            if (res.status !== 200) {
                throw new Error(yield res.text());
            }
        });
    }
    delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`/api/files/${id}`, { method: "DELETE" });
            if (res.status !== 200) {
                throw new Error(yield res.text());
            }
        });
    }
}
export function createRestfulBackend() {
    return {
        client: new RestClient(),
        subscription: new RestSubscription(),
        fileStorage: new RestFileStorage()
    };
}
