import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { PanelSizeContext } from "../context";
import { Loading } from "@pltk/components";
export const KeepRatio = (props) => {
    const outRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [outRect, setOutRect] = useState({ x: 0, y: 0, width: 1, height: 1 });
    const isOutRefAvailable = outRef.current !== null;
    useEffect(() => {
        if (outRef.current) {
            const { width, height, x, y } = outRef.current.getBoundingClientRect();
            setOutRect({ width, height, x, y });
            const widthScale = width / props.internalSize.width;
            const heightScale = height / props.internalSize.height;
            setScale(Math.min(widthScale, heightScale));
        }
    }, [outRef.current]);
    const fixedRatio = props.internalSize.width / props.internalSize.height;
    const panelSize = {
        scale,
        configSize: props.internalSize,
        actualRect: {
            x: outRect.x, y: outRect.y,
            width: props.internalSize.width * scale,
            height: props.internalSize.height * scale
        }
    };
    function onResize(newRect) {
        const width = newRect.width;
        const scale = width / props.internalSize.width;
        setScale(scale);
    }
    const internal = outRef.current === null ? _jsx(Loading, {}) : (_jsx(Rnd, Object.assign({ size: {
            width: props.internalSize.width * scale,
            height: props.internalSize.height * scale
        }, disableDragging: true, enableResizing: { right: true, bottom: true, bottomRight: true }, lockAspectRatio: fixedRatio, onResize: (e, direction, ref, delta, position) => onResize(ref.getBoundingClientRect()), style: {
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: "black",
            overflow: "hidden"
        } }, { children: _jsx(PanelSizeContext.Provider, Object.assign({ value: panelSize }, { children: props.children })) })));
    return _jsx(_Fragment, { children: _jsx("div", Object.assign({ style: { position: "absolute", width: "100%", height: "100%" }, ref: outRef }, { children: internal })) });
};
