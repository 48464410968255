var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useContext, useEffect, useState } from "react";
import { useLiveToolkitFileStorage } from "./base";
export class FileObjectURLCache {
    constructor() {
        this.refCounter = {};
        this.objectURL = {};
    }
    getURL(id, loader) {
        return __awaiter(this, void 0, void 0, function* () {
            if (id in this.objectURL) {
                this.refCounter[id]++;
                return this.objectURL[id];
            }
            else {
                const data = yield loader(id);
                const url = URL.createObjectURL(data);
                this.refCounter[id] = 1;
                this.objectURL[id] = url;
                return url;
            }
        });
    }
    dispose(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const latestCount = --this.refCounter[id];
            if (latestCount === 0) {
                delete this.refCounter[id];
                const url = this.objectURL[id];
                delete this.objectURL[id];
                URL.revokeObjectURL(url);
            }
        });
    }
}
const FileObjectURLCacheContext = createContext(new FileObjectURLCache());
export const FileObjectURLCacheProvider = FileObjectURLCacheContext.Provider;
export function useFileId(fileId) {
    const store = useContext(FileObjectURLCacheContext);
    const [result, setResult] = useState({ status: "Pending" });
    const fileClient = useLiveToolkitFileStorage();
    useEffect(() => {
        if (fileId) {
            setResult({ status: "Pending" });
            store.getURL(fileId, (id) => {
                return fileClient.fetch(id);
            }).then(url => {
                if (url) {
                    setResult({ status: "Loaded", url });
                }
            });
            return () => store.dispose(fileId);
        }
        else {
            setResult({ status: "NotFound" });
        }
        return () => { };
    }, [fileId]);
    return result;
}
export function readFileToBlob(file) {
    return __awaiter(this, void 0, void 0, function* () {
        const reader = file.stream().getReader();
        let res = yield reader.read();
        const parts = [];
        while (!res.done) {
            parts.push(res.value);
            res = yield reader.read();
        }
        if (res.value !== undefined) {
            parts.push(res.value);
        }
        return new Blob(parts);
    });
}
