import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { unwrapAsyncBinding, useLocalDBinding } from "@pltk/components";
import { usePanelId, useWidgetConfigBinding, useWidgetId, useWidgetRectBinding, WidgetConfigInternalProvider } from "@pltk/core";
import { useEnabledWidgets, WidgetContextProvider } from "@pltk/core";
import { EditableStateContext } from "../context";
import { EditableState } from "./base";
import { EditableBody } from "./editable";
import { PreviewFramework } from "./frameworks";
export function EditableWidget(props) {
    const enabledWidgets = useEnabledWidgets();
    const meta = props.widget.meta;
    const stateBinding = useLocalDBinding(EditableState.Edit);
    const panelId = usePanelId();
    const widgetId = useWidgetId();
    const configReq = useWidgetConfigBinding(panelId, widgetId);
    const widgetDef = enabledWidgets[meta.type];
    if (!widgetDef) {
        return _jsxs("div", { children: ["\u672A\u5B89\u88C5\u7EC4\u4EF6: ", meta.type] });
    }
    return unwrapAsyncBinding(configReq, configBinding => {
        return _jsx(WidgetContextProvider, Object.assign({ panelId: panelId, widgetId: widgetId, widgetDefinition: widgetDef }, { children: _jsx(WidgetConfigInternalProvider, Object.assign({ configBinding: configBinding }, { children: _jsx(EditableStateContext.Provider, Object.assign({ value: stateBinding }, { children: _jsx(EditableBody, { render: {
                            preview: () => widgetDef.render.preview(),
                            edit: () => widgetDef.render.edit(),
                            move: () => widgetDef.render.move()
                        } }) })) })) }));
    });
}
export function PreviewWidget(props) {
    const enabledWidgets = useEnabledWidgets();
    const meta = props.widget.meta;
    const panelId = usePanelId();
    const widgetId = useWidgetId();
    const widgetRectReq = useWidgetRectBinding(panelId, widgetId);
    const configReq = useWidgetConfigBinding(panelId, widgetId);
    const widgetDef = enabledWidgets[meta.type];
    if (!widgetDef) {
        return _jsxs("div", { children: ["`\u672A\u5B89\u88C5\u7EC4\u4EF6: $", meta.type, "`"] });
    }
    return unwrapAsyncBinding(widgetRectReq, rectBinding => {
        return unwrapAsyncBinding(configReq, configBinding => {
            return _jsx(WidgetContextProvider, Object.assign({ panelId: panelId, widgetId: widgetId, widgetDefinition: widgetDef }, { children: _jsx(WidgetConfigInternalProvider, Object.assign({ configBinding: configBinding }, { children: _jsx(PreviewFramework, Object.assign({ rect: rectBinding.value }, { children: widgetDef.render.preview() })) })) }));
        });
    });
}
