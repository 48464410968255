"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Divider = exports.CardWithActions = exports.SimpleCard = exports.CardGrid = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
function CardGrid(props) {
    var itemStyle = __assign({}, props.itemStyle);
    if (props.columns !== undefined) {
        itemStyle.width = "".concat(Math.floor(100 / props.columns), "%");
    }
    return (0, jsx_runtime_1.jsx)(antd_1.Card, __assign({ title: props.title, style: props.style }, { children: props.items.map(function (item, i) {
            var _a;
            return ((0, jsx_runtime_1.jsx)(antd_1.Card.Grid, __assign({ onClick: item.onClick, style: __assign(__assign({}, itemStyle), item.style) }, { children: item.content }), (_a = item.key) !== null && _a !== void 0 ? _a : i));
        }) }));
}
exports.CardGrid = CardGrid;
function SimpleCard(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Card, { children: props.children });
}
exports.SimpleCard = SimpleCard;
function CardWithActions(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Card, __assign({ title: props.title, actions: props.actions, style: props.style }, { children: props.children }));
}
exports.CardWithActions = CardWithActions;
function Divider() {
    return (0, jsx_runtime_1.jsx)(antd_1.Divider, {});
}
exports.Divider = Divider;
