import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertTextStyleToCSS, FormItem, Grid, MultiLinesStringField, propertyBinding, TextStyleAndSizePicker } from "@pltk/components";
import { useWidgetConfigInternal } from "@pltk/core";
import "@fontsource/zcool-kuaile";
const DEFAULT_FONT = '"ZCOOL KuaiLe"';
function Text() {
    const configBinding = useWidgetConfigInternal();
    return _jsx("div", Object.assign({ style: Object.assign({}, convertTextStyleToCSS(configBinding.value.textStyle)) }, { children: configBinding.value.content }));
}
function TextConfig() {
    const configBinding = useWidgetConfigInternal();
    const contentStore = propertyBinding(configBinding, "content");
    const textStyle = propertyBinding(configBinding, "textStyle");
    console.log("Rerender", configBinding.value);
    return _jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(TextStyleAndSizePicker, { binding: textStyle }) })), _jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(FormItem, Object.assign({ label: "\u5185\u5BB9" }, { children: _jsx(MultiLinesStringField, { binding: contentStore, rows: 5 }) })) })), _jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(Text, {}) }))] }));
}
export const TextPlugin = {
    title: "文本信息",
    type: "builtin.textView",
    initialize: {
        defaultConfig: () => ({
            content: "新文本",
            textStyle: {
                fontFamily: DEFAULT_FONT,
                borderColor: "black",
                borderWidth: 2,
                textColor: "white",
                fontSize: 60,
                alignment: "left"
            }
        }),
        defaultSize: () => ({ width: 300, height: 200 })
    },
    render: {
        preview: () => _jsx(Text, {}),
        move: () => _jsx(Text, {}),
        edit: () => _jsx(Text, {}),
        config: () => _jsx(TextConfig, {})
    }
};
export default TextPlugin;
