import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertTextStyleToCSS, TextStyleAndSizePicker } from "@pltk/components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useWidgetConfigInternal } from "@pltk/core";
import { FormItem, Grid, propertyBinding, StringField } from "@pltk/components";
import "@fontsource/baumans";
const DEFAULT_FONT = '"Baumans"';
function getCurrentTimeOnFormat(format) {
    return moment().format(format);
}
function useDate(format) {
    const [date, setDate] = useState(getCurrentTimeOnFormat(format));
    useEffect(() => {
        const timer = setInterval(() => setDate(getCurrentTimeOnFormat(format)), 1000);
        return () => clearInterval(timer);
    }, []);
    return date;
}
const Clock = () => {
    const configBinding = useWidgetConfigInternal();
    const date = useDate(configBinding.value.format);
    return _jsx("div", Object.assign({ style: Object.assign({ width: "100%", height: "100%" }, convertTextStyleToCSS(configBinding.value.textStyle)) }, { children: date }));
};
const ClockConfiguration = () => {
    const configBinding = useWidgetConfigInternal();
    const format = propertyBinding(configBinding, "format");
    const textStyle = propertyBinding(configBinding, "textStyle");
    return _jsxs(Grid, Object.assign({ container: true, alignment: "left" }, { children: [_jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(FormItem, Object.assign({ label: "\u65F6\u95F4\u683C\u5F0F" }, { children: _jsx(StringField, { binding: format }) })) })), _jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(TextStyleAndSizePicker, { binding: textStyle }) })), _jsx(Grid, Object.assign({ span: 12 }, { children: _jsx("div", Object.assign({ style: { height: "100px" } }, { children: _jsx(Clock, {}) })) }))] }));
};
function getDefaultTextStyle() {
    return {
        fontFamily: DEFAULT_FONT,
        borderColor: "#333333",
        borderWidth: 2.5,
        textColor: "white",
        alignment: "left",
        fontSize: 128
    };
}
const ClockPlugin = {
    title: "时钟",
    type: "builtin.clock",
    initialize: {
        defaultConfig: () => ({
            format: "HH:mm",
            textStyle: getDefaultTextStyle()
        }),
        defaultSize: () => ({ width: 300, height: 150 })
    },
    render: {
        preview: () => _jsx(Clock, {}),
        edit: () => _jsx(Clock, {}),
        move: () => _jsx(Clock, {}),
        config: () => _jsx(ClockConfiguration, {})
    }
};
export default ClockPlugin;
