var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionButton, CardGrid, defaultValueBinding, Dialog, Flex, FormItem, Grid, Icons, nullablePropertyBinding, NumberField, StringField, unwrapAsyncSubs, useLocalDBinding } from "@pltk/components";
import { useNavigate } from "react-router-dom";
import { useLiveToolkitClient, usePanels } from "@pltk/core";
import "./PanelList.css";
function defaultNewPanel() {
    return {
        title: "新界面",
        width: 1920,
        height: 1080
    };
}
function NewPanelDialog({ binding }) {
    const client = useLiveToolkitClient();
    return _jsx(Dialog, Object.assign({ title: "\u65B0\u5EFA\u754C\u9762", open: binding.value !== null, onOk: () => __awaiter(this, void 0, void 0, function* () {
            if (binding.value === null)
                return;
            const { title, width, height } = binding.value;
            yield client.createPanel({
                meta: { title },
                size: { width, height }
            });
            binding.update(null);
        }), onCancel: () => binding.update(null) }, { children: _jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(FormItem, Object.assign({ label: "\u6807\u9898" }, { children: _jsx(StringField, { binding: defaultValueBinding(nullablePropertyBinding(binding, "title"), "") }) })) })), _jsx(Grid, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ label: "\u5BBD\u5EA6" }, { children: _jsx(NumberField, { binding: defaultValueBinding(nullablePropertyBinding(binding, "width"), 1) }) })) })), _jsx(Grid, Object.assign({ span: 6 }, { children: _jsx(FormItem, Object.assign({ label: "\u9AD8\u5EA6" }, { children: _jsx(NumberField, { binding: defaultValueBinding(nullablePropertyBinding(binding, "height"), 1) }) })) }))] })) }));
}
export const RenderPanelListPageBody = () => {
    const navigate = useNavigate();
    const panelsReq = usePanels();
    return unwrapAsyncSubs(panelsReq, panels => (_jsx(CardGrid, { columns: 4, items: panels.map(p => ({
            key: p.id,
            onClick: () => navigate(`/panel/${p.id}`),
            content: (_jsx("span", Object.assign({ style: { fontSize: "1.8rem" } }, { children: p.meta.title }))),
        })) })));
};
export const PanelListPage = () => {
    const newPanel = useLocalDBinding(null);
    return _jsxs("div", Object.assign({ style: { margin: "50px" } }, { children: [_jsxs(Grid, Object.assign({ container: true, alignment: "left" }, { children: [_jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(Flex, { children: _jsx(ActionButton, Object.assign({ icon: _jsx(Icons.Add, {}), onClick: () => newPanel.update(defaultNewPanel()) }, { children: "\u521B\u5EFA\u65B0\u9762\u677F" })) }) })), _jsx(Grid, Object.assign({ span: 12 }, { children: _jsx(RenderPanelListPageBody, {}) }))] })), _jsx(NewPanelDialog, { binding: newPanel })] }));
};
