var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class CacheStore {
    constructor(client, subs) {
        this.client = client;
        this.subs = subs;
        this.idCounter = 0;
        this.store = new Map();
        this.disposers = new Map();
    }
    registerCallback(evt, fetcher, cb) {
        const key = this.stringifyEvent(evt);
        let c = this.store.get(key);
        let subscribe = false;
        if (!c) {
            c = new Map();
            this.store.set(key, c);
            subscribe = true;
        }
        const id = this.idCounter++;
        c.set(id, cb);
        const triggerUpdate = () => __awaiter(this, void 0, void 0, function* () {
            const v = yield fetcher();
            this.emitToCallback(evt, v);
        });
        if (subscribe) {
            const d = this.subs.subscribe(evt, triggerUpdate);
            this.disposers.set(key, d);
        }
        triggerUpdate();
        return {
            close: () => {
                let c = this.store.get(key);
                if (!c)
                    return;
                c.delete(id);
                if (c.size === 0) {
                    this.store.delete(key);
                    const d = this.disposers.get(key);
                    d === null || d === void 0 ? void 0 : d.close();
                    this.disposers.delete(key);
                }
            }
        };
    }
    emitToCallback(evt, value) {
        const key = this.stringifyEvent(evt);
        const c = this.store.get(key);
        if (!c)
            return;
        for (const cb of c.values()) {
            setTimeout(() => cb(value), 0);
        }
    }
    stringifyEvent(evt) {
        const parts = [evt.type];
        if ("panelId" in evt.parameters) {
            parts.push(`panelId=${evt.parameters.panelId}`);
        }
        if ("widgetId" in evt.parameters) {
            parts.push(`widgetId=${evt.parameters.widgetId}`);
        }
        if ("warehouseType" in evt.parameters) {
            parts.push(`warehouseType=${evt.parameters.warehouseType}`);
        }
        if ("warehouseId" in evt.parameters) {
            parts.push(`warehouseId=${evt.parameters.warehouseId}`);
        }
        return parts.join("_");
    }
    subscribePanelList(cb) {
        return this.registerCallback({ type: "PanelList", parameters: {} }, () => this.client.getPanels(), cb);
    }
    subscribePanel(panelId, cb) {
        return this.registerCallback({ type: "Panel", parameters: { panelId } }, () => this.client.getPanel(panelId), cb);
    }
    subscribeWidgets(panelId, cb) {
        return this.registerCallback({ type: "WidgetListOfPanel", parameters: { panelId } }, () => this.client.getWidgetsOfPanel(panelId), cb);
    }
    subscribeWidgetRect(panelId, widgetId, cb) {
        return this.registerCallback({ type: "WidgetRect", parameters: { panelId, widgetId } }, () => this.client.getWidgetRect(panelId, widgetId), cb);
    }
    subscribeWidgetConfig(panelId, widgetId, cb) {
        return this.registerCallback({ type: "WidgetConfig", parameters: { panelId, widgetId } }, () => this.client.getWidgetConfig(panelId, widgetId), cb);
    }
    subscribeWarehouseList(type, cb) {
        return this.registerCallback({ type: "WarehouseList", parameters: { warehouseType: type } }, () => this.client.getWarehouseList(type), cb);
    }
    subscribeWarehouseMeta(type, id, cb) {
        return this.registerCallback({ type: "WarehouseMeta", parameters: { warehouseType: type, warehouseId: id } }, () => this.client.getWarehouseMeta(type, id), cb);
    }
    subscribeWarehouseConfig(type, id, cb) {
        return this.registerCallback({ type: "WarehouseConfig", parameters: { warehouseType: type, warehouseId: id } }, () => this.client.getWarehouseConfig(type, id), cb);
    }
}
