"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Home = exports.Save = exports.Image = exports.Upload = exports.Down = exports.Up = exports.Completed = exports.Pending = exports.Hide = exports.Show = exports.Share = exports.Move = exports.Minimize = exports.Close = exports.Ok = exports.Delete = exports.Edit = exports.Add = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var icons = __importStar(require("@ant-design/icons"));
var iconSizeMapping = {
    small: 32,
    middle: 48,
    large: 64
};
var warpIcon = function (Icon) { return function (props) { return ((0, jsx_runtime_1.jsx)(Icon, { style: {
        fontSize: props.size && iconSizeMapping[props.size],
        color: props.color
    } })); }; };
exports.Add = warpIcon(icons.PlusOutlined);
exports.Edit = warpIcon(icons.EditOutlined);
exports.Delete = warpIcon(icons.DeleteOutlined);
exports.Ok = warpIcon(icons.CheckOutlined);
exports.Close = warpIcon(icons.CloseOutlined);
exports.Minimize = warpIcon(icons.LineOutlined);
exports.Move = warpIcon(icons.DragOutlined);
exports.Share = warpIcon(icons.ShareAltOutlined);
exports.Show = warpIcon(icons.EyeOutlined);
exports.Hide = warpIcon(icons.EyeInvisibleOutlined);
exports.Pending = warpIcon(icons.ClockCircleOutlined);
exports.Completed = warpIcon(icons.CheckOutlined);
exports.Up = warpIcon(icons.ArrowUpOutlined);
exports.Down = warpIcon(icons.ArrowDownOutlined);
exports.Upload = warpIcon(icons.UploadOutlined);
exports.Image = warpIcon(icons.PictureOutlined);
exports.Save = warpIcon(icons.SaveOutlined);
exports.Home = warpIcon(icons.HomeOutlined);
