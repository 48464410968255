"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectWithFilter = exports.Select = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
function Select(props) {
    return (0, jsx_runtime_1.jsx)(antd_1.Select, { defaultOpen: false, value: props.binding.value, onChange: function (value) { return props.binding.update(value); }, options: props.options });
}
exports.Select = Select;
function defaultSelectFilter(input, value, label) {
    var _a, _b, _c;
    var inputText = (_a = input === null || input === void 0 ? void 0 : input.toString().toLowerCase()) !== null && _a !== void 0 ? _a : "";
    var valueText = (_b = value === null || value === void 0 ? void 0 : value.toString().toLowerCase()) !== null && _b !== void 0 ? _b : "";
    var labelText = (_c = label === null || label === void 0 ? void 0 : label.toString().toLowerCase()) !== null && _c !== void 0 ? _c : "";
    return valueText.includes(inputText) || labelText.includes(inputText);
}
function SelectWithFilter(props) {
    var _a;
    var optionFilter = (_a = props.optionFilter) !== null && _a !== void 0 ? _a : defaultSelectFilter;
    return (0, jsx_runtime_1.jsx)(antd_1.Select, { value: props.binding.value, placeholder: props.placeholder, onChange: function (value) {
            props.binding.update(value);
        }, optionFilterProp: "children", filterOption: function (input, option) { return optionFilter(input, option === null || option === void 0 ? void 0 : option.value, option === null || option === void 0 ? void 0 : option.label); }, options: props.options });
}
exports.SelectWithFilter = SelectWithFilter;
