"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarehouseClient = exports.PluginClient = exports.PanelClient = exports.GlobalClient = exports.SubscriptionWrapper = exports.APIWrapper = exports.KVPathBuilder = void 0;
var utils_1 = require("./utils");
var KVPathBuilder = /** @class */ (function () {
    function KVPathBuilder() {
    }
    KVPathBuilder.prototype.panelsCounter = function () {
        return "/panels/counter";
    };
    KVPathBuilder.prototype.panelsList = function () {
        return "/panels/enabled";
    };
    KVPathBuilder.prototype.panelMeta = function (panelId) {
        return "/panel/".concat(panelId, "/meta");
    };
    KVPathBuilder.prototype.panelSize = function (panelId) {
        return "/panel/".concat(panelId, "/size");
    };
    KVPathBuilder.prototype.pluginsCounter = function () {
        return "/plugins/counter";
    };
    KVPathBuilder.prototype.pluginsList = function (panelId) {
        return "/panel/".concat(panelId, "/plugins/enabled");
    };
    KVPathBuilder.prototype.pluginMeta = function (pluginId) {
        return "/plugin/".concat(pluginId, "/meta");
    };
    KVPathBuilder.prototype.pluginRect = function (panelId, pluginId) {
        return "/panel/".concat(panelId, "/plugin/").concat(pluginId, "/rect");
    };
    KVPathBuilder.prototype.pluginConfig = function (pluginId) {
        return "/plugin/".concat(pluginId, "/config");
    };
    KVPathBuilder.prototype.warehouseCounter = function (type) {
        return "/warehouses/".concat(type, "/counter");
    };
    KVPathBuilder.prototype.warehouseList = function (type) {
        return "/warehouses/".concat(type, "/enabled");
    };
    KVPathBuilder.prototype.warehouseMeta = function (type, id) {
        return "/warehouse/".concat(type, "/").concat(id, "/title");
    };
    KVPathBuilder.prototype.warehouseConfig = function (type, id) {
        return "/warehouse/".concat(type, "/").concat(id, "/config");
    };
    return KVPathBuilder;
}());
exports.KVPathBuilder = KVPathBuilder;
var APIWrapper = /** @class */ (function () {
    function APIWrapper(clientFactory) {
        this.clientFactory = clientFactory;
    }
    Object.defineProperty(APIWrapper.prototype, "paths", {
        get: function () {
            return new KVPathBuilder();
        },
        enumerable: false,
        configurable: true
    });
    APIWrapper.prototype.panelsCounter = function () {
        return this.clientFactory(this.paths.panelsCounter());
    };
    APIWrapper.prototype.panelsList = function () {
        return this.clientFactory(this.paths.panelsList());
    };
    APIWrapper.prototype.panelMeta = function (panelId) {
        return this.clientFactory(this.paths.panelMeta(panelId));
    };
    APIWrapper.prototype.panelSize = function (panelId) {
        return this.clientFactory(this.paths.panelSize(panelId));
    };
    APIWrapper.prototype.pluginsCounter = function () {
        return this.clientFactory(this.paths.pluginsCounter());
    };
    APIWrapper.prototype.pluginsList = function (panelId) {
        return this.clientFactory(this.paths.pluginsList(panelId));
    };
    APIWrapper.prototype.pluginMeta = function (pluginId) {
        return this.clientFactory(this.paths.pluginMeta(pluginId));
    };
    APIWrapper.prototype.pluginRect = function (panelId, pluginId) {
        return this.clientFactory(this.paths.pluginRect(panelId, pluginId));
    };
    APIWrapper.prototype.pluginConfig = function (pluginId) {
        return this.clientFactory(this.paths.pluginConfig(pluginId));
    };
    APIWrapper.prototype.warehouseCounter = function (type) {
        return this.clientFactory(this.paths.warehouseCounter(type));
    };
    APIWrapper.prototype.warehouseList = function (type) {
        return this.clientFactory(this.paths.warehouseList(type));
    };
    APIWrapper.prototype.warehouseMeta = function (type, id) {
        return this.clientFactory(this.paths.warehouseMeta(type, id));
    };
    APIWrapper.prototype.warehouseConfig = function (type, id) {
        return this.clientFactory(this.paths.warehouseConfig(type, id));
    };
    return APIWrapper;
}());
exports.APIWrapper = APIWrapper;
var SubscriptionWrapper = /** @class */ (function () {
    function SubscriptionWrapper(factory) {
        this.factory = factory;
    }
    Object.defineProperty(SubscriptionWrapper.prototype, "paths", {
        get: function () {
            return new KVPathBuilder();
        },
        enumerable: false,
        configurable: true
    });
    SubscriptionWrapper.prototype.subscribePanels = function (cb) {
        return this.factory(this.paths.panelsList()).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribePanelMeta = function (panelId, cb) {
        return this.factory(this.paths.panelMeta(panelId)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribePanelSize = function (panelId, cb) {
        return this.factory(this.paths.panelSize(panelId)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribePlugins = function (panelId, cb) {
        return this.factory(this.paths.pluginsList(panelId)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribePluginSize = function (panelId, pluginId, cb) {
        return this.factory(this.paths.pluginRect(panelId, pluginId)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribePluginConfig = function (panelId, pluginId, cb) {
        return this.factory(this.paths.pluginConfig(pluginId)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribeWarehouseList = function (type, cb) {
        return this.factory(this.paths.warehouseList(type)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribeWarehouseMeta = function (type, id, cb) {
        return this.factory(this.paths.warehouseMeta(type, id)).subscribe(cb);
    };
    SubscriptionWrapper.prototype.subscribeWarehouseConfig = function (type, id, cb) {
        return this.factory(this.paths.warehouseConfig(type, id)).subscribe(cb);
    };
    return SubscriptionWrapper;
}());
exports.SubscriptionWrapper = SubscriptionWrapper;
var GlobalClient = /** @class */ (function () {
    function GlobalClient(api) {
        this.api = api;
    }
    GlobalClient.prototype.panels = function () {
        return __awaiter(this, void 0, void 0, function () {
            var idList;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.panelsList().get()];
                    case 1:
                        idList = _a.sent();
                        if (idList === null) {
                            idList = [];
                        }
                        return [2 /*return*/, Promise.all(idList.map(function (id) { return __awaiter(_this, void 0, void 0, function () {
                                var meta;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, this.api.panelMeta(id).get()];
                                        case 1:
                                            meta = (_a = _b.sent()) !== null && _a !== void 0 ? _a : (0, utils_1.error)("Panel not exists: ".concat(id));
                                            return [2 /*return*/, { meta: meta, id: id }];
                                    }
                                });
                            }); }))];
                }
            });
        });
    };
    GlobalClient.prototype.createPanel = function (meta, size) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var panelId, panelList;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.panelsCounter().get()];
                    case 1:
                        panelId = (_a = _c.sent()) !== null && _a !== void 0 ? _a : 0;
                        return [4 /*yield*/, this.api.panelsList().get()];
                    case 2:
                        panelList = (_b = (_c.sent())) !== null && _b !== void 0 ? _b : [];
                        return [4 /*yield*/, Promise.all([
                                this.api.panelMeta(panelId).set(meta),
                                this.api.panelSize(panelId).set(size)
                            ])];
                    case 3:
                        _c.sent();
                        panelList.push(panelId);
                        return [4 /*yield*/, this.api.panelsList().set(panelList)];
                    case 4:
                        _c.sent();
                        return [4 /*yield*/, this.api.panelsCounter().set(panelId + 1)];
                    case 5:
                        _c.sent();
                        return [2 /*return*/, panelId];
                }
            });
        });
    };
    return GlobalClient;
}());
exports.GlobalClient = GlobalClient;
var PanelClient = /** @class */ (function () {
    function PanelClient(api, panelId) {
        this.api = api;
        this.panelId = panelId;
    }
    PanelClient.prototype.meta = function () {
        return __awaiter(this, void 0, void 0, function () {
            var meta;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.panelMeta(this.panelId).get()];
                    case 1:
                        meta = _a.sent();
                        return [2 /*return*/, meta !== null && meta !== void 0 ? meta : (0, utils_1.error)("Panel not exists")];
                }
            });
        });
    };
    PanelClient.prototype.setMeta = function (meta) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.panelMeta(this.panelId).set(meta)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PanelClient.prototype.size = function () {
        return __awaiter(this, void 0, void 0, function () {
            var size;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.panelSize(this.panelId).get()];
                    case 1:
                        size = _a.sent();
                        return [2 /*return*/, size !== null && size !== void 0 ? size : (0, utils_1.error)("Panel not exists")];
                }
            });
        });
    };
    PanelClient.prototype.resize = function (newSize) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.panelSize(this.panelId).set(newSize)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PanelClient.prototype.createPlugin = function (pluginType, size, config) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var pluginsList, pluginId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.pluginsList(this.panelId).get()];
                    case 1:
                        pluginsList = (_a = (_c.sent())) !== null && _a !== void 0 ? _a : [];
                        return [4 /*yield*/, this.api.pluginsCounter().get()];
                    case 2:
                        pluginId = (_b = (_c.sent())) !== null && _b !== void 0 ? _b : 0;
                        pluginsList.push(pluginId);
                        return [4 /*yield*/, this.api.pluginMeta(pluginId).set({ type: pluginType })];
                    case 3:
                        _c.sent();
                        return [4 /*yield*/, this.api.pluginRect(this.panelId, pluginId).set(size)];
                    case 4:
                        _c.sent();
                        return [4 /*yield*/, this.api.pluginConfig(pluginId).set(config)];
                    case 5:
                        _c.sent();
                        return [4 /*yield*/, this.api.pluginsCounter().set(pluginId + 1)];
                    case 6:
                        _c.sent();
                        return [4 /*yield*/, this.api.pluginsList(this.panelId).set(pluginsList)];
                    case 7:
                        _c.sent();
                        return [2 /*return*/, pluginId];
                }
            });
        });
    };
    PanelClient.prototype.pluginsList = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.api.pluginsList(this.panelId).get()];
                    case 1: return [2 /*return*/, (_a = _b.sent()) !== null && _a !== void 0 ? _a : []];
                }
            });
        });
    };
    PanelClient.prototype.delete = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var panelList, pluginList, _i, pluginList_1, pluginId;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.api.panelsList().get()];
                    case 1:
                        panelList = (_b = (_a = (_d.sent())) === null || _a === void 0 ? void 0 : _a.filter(function (id) { return id !== _this.panelId; })) !== null && _b !== void 0 ? _b : [];
                        return [4 /*yield*/, this.api.pluginsList(this.panelId).get()];
                    case 2:
                        pluginList = (_c = _d.sent()) !== null && _c !== void 0 ? _c : [];
                        return [4 /*yield*/, this.api.panelsList().set(panelList)];
                    case 3:
                        _d.sent();
                        _i = 0, pluginList_1 = pluginList;
                        _d.label = 4;
                    case 4:
                        if (!(_i < pluginList_1.length)) return [3 /*break*/, 7];
                        pluginId = pluginList_1[_i];
                        return [4 /*yield*/, new PluginClient(this.api, this.panelId, pluginId).delete()];
                    case 5:
                        _d.sent();
                        _d.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 4];
                    case 7: return [4 /*yield*/, this.api.pluginsCounter().delete()];
                    case 8:
                        _d.sent();
                        return [4 /*yield*/, this.api.pluginsList(this.panelId).delete()];
                    case 9:
                        _d.sent();
                        return [4 /*yield*/, this.api.panelMeta(this.panelId).delete()];
                    case 10:
                        _d.sent();
                        return [4 /*yield*/, this.api.panelSize(this.panelId).delete()];
                    case 11:
                        _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return PanelClient;
}());
exports.PanelClient = PanelClient;
var PluginClient = /** @class */ (function () {
    function PluginClient(api, panelId, pluginId) {
        this.api = api;
        this.panelId = panelId;
        this.pluginId = pluginId;
    }
    PluginClient.prototype.meta = function () {
        return __awaiter(this, void 0, void 0, function () {
            var meta;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.pluginMeta(this.pluginId).get()];
                    case 1:
                        meta = _a.sent();
                        return [2 /*return*/, meta !== null && meta !== void 0 ? meta : (0, utils_1.error)("Plugin not exists")];
                }
            });
        });
    };
    PluginClient.prototype.config = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.pluginConfig(this.pluginId).get()];
                    case 1:
                        config = _a.sent();
                        return [2 /*return*/, config !== null && config !== void 0 ? config : (0, utils_1.error)("Plugin not exists")];
                }
            });
        });
    };
    PluginClient.prototype.setMeta = function (meta) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.pluginMeta(this.pluginId).set(meta)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PluginClient.prototype.setConfig = function (config) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.pluginConfig(this.pluginId).set(config)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PluginClient.prototype.configOrNull = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.pluginConfig(this.pluginId).get()];
                    case 1:
                        config = _a.sent();
                        return [2 /*return*/, config !== null && config !== void 0 ? config : null];
                }
            });
        });
    };
    PluginClient.prototype.size = function () {
        return __awaiter(this, void 0, void 0, function () {
            var size;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.pluginRect(this.panelId, this.pluginId).get()];
                    case 1:
                        size = _a.sent();
                        return [2 /*return*/, size !== null && size !== void 0 ? size : (0, utils_1.error)("Plugin not exists")];
                }
            });
        });
    };
    PluginClient.prototype.resize = function (rect) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.pluginRect(this.panelId, this.pluginId).set(rect)];
            });
        });
    };
    PluginClient.prototype.delete = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var pluginList;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.api.pluginsList(this.panelId).get()];
                    case 1:
                        pluginList = (_a = (_b.sent())) !== null && _a !== void 0 ? _a : [];
                        return [4 /*yield*/, this.api.pluginsList(this.panelId).set(pluginList.filter(function (id) { return id !== _this.pluginId; }))];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.api.pluginMeta(this.pluginId).delete()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.api.pluginRect(this.panelId, this.pluginId).delete()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.api.pluginConfig(this.pluginId).delete()];
                    case 5:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return PluginClient;
}());
exports.PluginClient = PluginClient;
var WarehouseClient = /** @class */ (function () {
    function WarehouseClient(api) {
        this.api = api;
    }
    WarehouseClient.prototype.create = function (type, warehouse) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var id, data, list;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.warehouseCounter(type).get()];
                    case 1:
                        id = (_a = _c.sent()) !== null && _a !== void 0 ? _a : 0;
                        data = __assign({ type: type, id: id }, warehouse);
                        return [4 /*yield*/, this.api.warehouseList(type).get()];
                    case 2:
                        list = (_b = _c.sent()) !== null && _b !== void 0 ? _b : [];
                        return [4 /*yield*/, this.api.warehouseMeta(type, id).set(warehouse.meta)];
                    case 3:
                        _c.sent();
                        return [4 /*yield*/, this.api.warehouseConfig(type, id).set(warehouse.config)];
                    case 4:
                        _c.sent();
                        list.push(id);
                        return [4 /*yield*/, this.api.warehouseCounter(type).set(id + 1)];
                    case 5:
                        _c.sent();
                        return [4 /*yield*/, this.api.warehouseList(type).set(list)];
                    case 6:
                        _c.sent();
                        return [2 /*return*/, id];
                }
            });
        });
    };
    WarehouseClient.prototype.get = function (type, id) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var meta, config;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.api.warehouseMeta(type, id).get()];
                    case 1:
                        meta = (_a = _c.sent()) !== null && _a !== void 0 ? _a : (0, utils_1.error)("Warehouse not found: ".concat(type, ", ").concat(id));
                        return [4 /*yield*/, this.api.warehouseConfig(type, id).get()];
                    case 2:
                        config = (_b = _c.sent()) !== null && _b !== void 0 ? _b : (0, utils_1.error)("Warehouse not found: ".concat(type, ", ").concat(id));
                        return [2 /*return*/, { id: id, type: type, meta: meta, config: config }];
                }
            });
        });
    };
    WarehouseClient.prototype.list = function (type) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var idList;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.api.warehouseList(type).get()];
                    case 1:
                        idList = (_a = _b.sent()) !== null && _a !== void 0 ? _a : [];
                        return [4 /*yield*/, Promise.all(idList.map(function (id) { return __awaiter(_this, void 0, void 0, function () {
                                var meta;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, this.api.warehouseMeta(type, id).get()];
                                        case 1:
                                            meta = (_a = _b.sent()) !== null && _a !== void 0 ? _a : (0, utils_1.error)("Warehouse not found: ".concat(type, ", ").concat(id));
                                            return [2 /*return*/, { id: id, meta: meta }];
                                    }
                                });
                            }); }))];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    WarehouseClient.prototype.getMeta = function (type, id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, (_a = this.api.warehouseMeta(type, id).get()) !== null && _a !== void 0 ? _a : (0, utils_1.error)("Warehouse not found: ".concat(type, ", ").concat(id))];
            });
        });
    };
    WarehouseClient.prototype.getConfig = function (type, id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, (_a = this.api.warehouseConfig(type, id).get()) !== null && _a !== void 0 ? _a : (0, utils_1.error)("Warehouse not found: ".concat(type, ", ").concat(id))];
            });
        });
    };
    WarehouseClient.prototype.setMeta = function (type, id, meta) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.warehouseMeta(type, id).set(meta)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WarehouseClient.prototype.setConfig = function (type, id, config) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.warehouseConfig(type, id).set(config)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WarehouseClient.prototype.delete = function (type, id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var list;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.api.warehouseList(type).get()];
                    case 1:
                        list = (_a = _b.sent()) !== null && _a !== void 0 ? _a : [];
                        return [4 /*yield*/, this.api.warehouseList(type).set(list.filter(function (i) { return i !== id; }))];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.api.warehouseMeta(type, id).delete()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.api.warehouseConfig(type, id).delete()];
                    case 4:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return WarehouseClient;
}());
exports.WarehouseClient = WarehouseClient;
