import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNullableContext } from "@pltk/components";
import { useState } from "react";
import * as rnd from "react-rnd";
import { PanelSizeContext } from "../context";
export const PreviewFramework = (props) => {
    const { scale } = useNullableContext(PanelSizeContext);
    const { x, y, width, height } = props.rect;
    const style = Object.assign({ left: x * scale, top: y * scale, width: width * scale, height: height * scale, position: "absolute", overflow: "hidden" }, props.style);
    const wrapperStyle = {
        position: "absolute",
        left: 0, top: 0,
        width: `${100 / scale}%`,
        height: `${100 / scale}%`,
        transform: `scale(${scale})`,
        transformOrigin: "left top"
    };
    return _jsxs("div", Object.assign({ style: style }, { children: [_jsx("div", Object.assign({ style: wrapperStyle }, { children: props.children })), props.attachments] }));
};
export const ScaledFramework = (props) => {
    const { scale } = useNullableContext(PanelSizeContext);
    const { x, y, width, height } = props.rect;
    const style = Object.assign({ left: x * scale, top: y * scale, width: width * scale, height: height * scale, position: "absolute" }, props.style);
    const wrapperStyle = {
        position: "absolute",
        left: 0, top: 0,
        width: `${100 / scale}%`,
        height: `${100 / scale}%`,
        transform: `scale(${scale})`,
        transformOrigin: "left top",
        overflow: "hidden",
    };
    return _jsxs("div", Object.assign({ style: style }, { children: [_jsx("div", Object.assign({ style: wrapperStyle }, { children: props.children })), props.attachments] }));
};
function isValidRect({ x, y, width, height }, parentSize) {
    const safeGap = 5;
    const parentWidth = parentSize.width;
    const parentHeight = parentSize.height;
    const left = x, right = x + width, top = y, bottom = y + height;
    return left < parentWidth - safeGap && right > safeGap && top < parentHeight - safeGap && bottom > safeGap;
}
export function ResizableFramework(props) {
    const { scale, configSize, actualRect } = useNullableContext(PanelSizeContext);
    const [rect, setRect] = useState(applyRect(props.rect));
    function recoverRect({ x, y, width, height }) {
        return {
            x: x / scale,
            y: y / scale,
            width: width / scale,
            height: height / scale,
        };
    }
    function applyRect({ x, y, width, height }) {
        return {
            x: x * scale,
            y: y * scale,
            width: width * scale,
            height: height * scale
        };
    }
    function onUpdateHTMLElement(ref) {
        let { x, y, width, height } = ref.getBoundingClientRect();
        x -= actualRect.x;
        y -= actualRect.y;
        const rect = { x, y, width, height };
        if (isValidRect(rect, actualRect)) {
            setRect(rect);
        }
    }
    function onUpdateHTMLElementSaved(ref) {
        let { x, y, width, height } = ref.getBoundingClientRect();
        x -= actualRect.x;
        y -= actualRect.y;
        const rect = { x, y, width, height };
        if (isValidRect(rect, configSize)) {
            props.onSizeChanged(recoverRect(rect));
        }
    }
    return _jsxs(rnd.Rnd, Object.assign({ style: props.style, size: rect, position: rect, onResize: (e, direction, ref, delta, position) => onUpdateHTMLElement(ref), onDrag: (e, data) => onUpdateHTMLElement(data.node), onResizeStop: (e, direction, ref, delta, position) => onUpdateHTMLElementSaved(ref), onDragStop: (e, data) => onUpdateHTMLElementSaved(data.node) }, { children: [_jsx("div", Object.assign({ style: {
                    position: "absolute",
                    left: 0, top: 0,
                    width: `${100 / scale}%`, height: `${100 / scale}%`,
                    transformOrigin: "left top",
                    transform: `scale(${scale})`,
                    overflow: "hidden"
                } }, { children: props.children })), props.attachments] }));
}
