import { useState, useEffect } from "react";
import { useAPISubscriptionCache, useLiveToolkitClient as useClient } from "./base";
function useSubsCacheResult(deps, subs) {
    const cache = useAPISubscriptionCache();
    const [result, setResult] = useState({ status: "pending" });
    useEffect(() => {
        const d = subs(cache, v => setResult({ status: "success", value: v }));
        return () => d.close();
    }, deps);
    return result;
}
function resultToBinding(result, setter) {
    if (result.status === "success") {
        return {
            status: "success",
            binding: {
                value: result.value,
                update: setter
            }
        };
    }
    else {
        return result;
    }
}
export function usePanels() {
    return useSubsCacheResult([], (c, cb) => c.subscribePanelList(cb));
}
export function usePanel(panelId) {
    return useSubsCacheResult([panelId], (c, cb) => c.subscribePanel(panelId, cb));
}
export function useWidgetListOfPanel(panelId) {
    return useSubsCacheResult([panelId], (c, cb) => c.subscribeWidgets(panelId, cb));
}
export function useWidgetRectBinding(panelId, widgetId) {
    const client = useClient();
    const result = useSubsCacheResult([panelId, widgetId], (c, cb) => c.subscribeWidgetRect(panelId, widgetId, cb));
    return resultToBinding(result, value => client.setWidgetRect(panelId, widgetId, value));
}
export function useWidgetConfigBinding(panelId, widgetId) {
    const client = useClient();
    const result = useSubsCacheResult([panelId, widgetId], (c, cb) => c.subscribeWidgetConfig(panelId, widgetId, cb));
    return resultToBinding(result, value => client.setWidgetConfig(panelId, widgetId, value));
}
export function useWarehouseList(warehouseType) {
    return useSubsCacheResult([warehouseType], (c, cb) => c.subscribeWarehouseList(warehouseType, cb));
}
export function useWarehouseMetaBinding(warehouseType, warehouseId) {
    const client = useClient();
    const result = useSubsCacheResult([warehouseType, warehouseId], (c, cb) => c.subscribeWarehouseMeta(warehouseType, warehouseId, cb));
    return resultToBinding(result, value => client.setWarehouseMeta(warehouseType, warehouseId, value));
}
export function useWarehouseConfigBinding(warehouseType, warehouseId) {
    const client = useClient();
    const result = useSubsCacheResult([warehouseType, warehouseId], (c, cb) => c.subscribeWarehouseConfig(warehouseType, warehouseId, cb));
    return resultToBinding(result, value => client.setWarehouseConfig(warehouseType, warehouseId, value));
}
