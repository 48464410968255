"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTemporaryBinding = exports.haltBinding = exports.nullablePropertyBinding = exports.mapBinding = exports.validateBinding = exports.arrayBinding = exports.memoryBinding = exports.defaultValueBinding = exports.propertyBinding = exports.useLocalDBinding = exports.createReadonlyDBinding = exports.createDBinding = void 0;
var react_1 = require("react");
function createDBinding(_a) {
    var _this = this;
    var value = _a.value, update = _a.update;
    return {
        value: value,
        update: function (v) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, update(v)];
        }); }); },
    };
}
exports.createDBinding = createDBinding;
function createReadonlyDBinding(value) {
    return createDBinding({ value: value, update: function () { } });
}
exports.createReadonlyDBinding = createReadonlyDBinding;
function useLocalDBinding(defaultValue) {
    var _a = (0, react_1.useState)(defaultValue), value = _a[0], update = _a[1];
    return createDBinding({ value: value, update: update });
}
exports.useLocalDBinding = useLocalDBinding;
var PropertyBinding = /** @class */ (function () {
    function PropertyBinding(store, key) {
        this.store = store;
        this.key = key;
    }
    Object.defineProperty(PropertyBinding.prototype, "value", {
        get: function () {
            return this.store.value[this.key];
        },
        enumerable: false,
        configurable: true
    });
    PropertyBinding.prototype.update = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.store.update(__assign(__assign({}, this.store.value), (_a = {}, _a[this.key] = value, _a)))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyBinding.prototype.to = function (key) {
        return new PropertyBinding(this, key);
    };
    return PropertyBinding;
}());
function propertyBinding(parent, key) {
    return new PropertyBinding(parent, key);
}
exports.propertyBinding = propertyBinding;
function defaultValueBinding(parent, defaultValue) {
    return {
        get value() { var _a; return (_a = parent.value) !== null && _a !== void 0 ? _a : defaultValue; },
        update: function (value) { return parent.update(value); }
    };
}
exports.defaultValueBinding = defaultValueBinding;
var MemoryBinding = /** @class */ (function () {
    function MemoryBinding(_value) {
        this._value = _value;
    }
    Object.defineProperty(MemoryBinding.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: false,
        configurable: true
    });
    MemoryBinding.prototype.update = function (newValue) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._value = newValue;
                return [2 /*return*/];
            });
        });
    };
    return MemoryBinding;
}());
function memoryBinding(initialValue) {
    return new MemoryBinding(initialValue);
}
exports.memoryBinding = memoryBinding;
var ArrayBinding = /** @class */ (function () {
    function ArrayBinding(parent) {
        this.parent = parent;
    }
    Object.defineProperty(ArrayBinding.prototype, "value", {
        get: function () {
            return this.parent.value;
        },
        enumerable: false,
        configurable: true
    });
    ArrayBinding.prototype.update = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.parent.update(value)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ArrayBinding.prototype, "items", {
        get: function () {
            var _this = this;
            return this.parent.value.map(function (v, i) { return new ArrayItemBinding(_this, i); });
        },
        enumerable: false,
        configurable: true
    });
    ArrayBinding.prototype.warpInternalUpdate = function (updater) {
        return __awaiter(this, void 0, void 0, function () {
            var newArray;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newArray = Array.from(this.parent.value);
                        updater(newArray);
                        return [4 /*yield*/, this.parent.update(newArray)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArrayBinding.prototype.append = function (value) {
        return this.warpInternalUpdate(function (items) {
            items.push(value);
        });
    };
    ArrayBinding.prototype.setItem = function (i, value) {
        return this.warpInternalUpdate(function (items) {
            items[i] = value;
        });
    };
    ArrayBinding.prototype.remove = function (i) {
        return this.warpInternalUpdate(function (items) {
            items.splice(i, 1);
        });
    };
    ArrayBinding.prototype.insert = function (i, value) {
        return this.warpInternalUpdate(function (items) {
            items.splice(i, 0, value);
        });
    };
    ArrayBinding.prototype.move = function (fromIndex, toIndex) {
        return this.warpInternalUpdate(function (items) {
            var item = items[fromIndex];
            items.splice(fromIndex, 1);
            items.splice(toIndex, 0, item);
        });
    };
    return ArrayBinding;
}());
var ArrayItemBinding = /** @class */ (function () {
    function ArrayItemBinding(parent, index) {
        this.parent = parent;
        this.index = index;
    }
    Object.defineProperty(ArrayItemBinding.prototype, "value", {
        get: function () {
            return this.parent.value[this.index];
        },
        enumerable: false,
        configurable: true
    });
    ArrayItemBinding.prototype.update = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.parent.setItem(this.index, value)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArrayItemBinding.prototype.remove = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.parent.remove(this.index)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArrayItemBinding.prototype.move = function (toIndex) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.parent.move(this.index, toIndex)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ArrayItemBinding;
}());
function arrayBinding(parent) {
    return new ArrayBinding(parent);
}
exports.arrayBinding = arrayBinding;
var ValidateBinding = /** @class */ (function () {
    function ValidateBinding(parent, validator) {
        this.parent = parent;
        this.validator = validator;
    }
    Object.defineProperty(ValidateBinding.prototype, "value", {
        get: function () {
            return this.parent.value;
        },
        enumerable: false,
        configurable: true
    });
    ValidateBinding.prototype.update = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.validator(value)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.parent.update(value)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return ValidateBinding;
}());
function validateBinding(parent, validator) {
    return new ValidateBinding(parent, validator);
}
exports.validateBinding = validateBinding;
function mapBinding(parent, props) {
    return {
        get value() { return props.forward(parent.value); },
        update: function (value) { return parent.update(props.backward(value, parent.value)); }
    };
}
exports.mapBinding = mapBinding;
var NullablePropertyBinding = /** @class */ (function () {
    function NullablePropertyBinding(store, key) {
        this.store = store;
        this.key = key;
    }
    Object.defineProperty(NullablePropertyBinding.prototype, "value", {
        get: function () {
            if (this.store.value === null)
                return null;
            return this.store.value[this.key];
        },
        enumerable: false,
        configurable: true
    });
    NullablePropertyBinding.prototype.update = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.store.value === null)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.store.update(__assign(__assign({}, this.store.value), (_a = {}, _a[this.key] = value, _a)))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NullablePropertyBinding.prototype.to = function (key) {
        return new NullablePropertyBinding(this, key);
    };
    return NullablePropertyBinding;
}());
function nullablePropertyBinding(parent, key) {
    return new NullablePropertyBinding(parent, key);
}
exports.nullablePropertyBinding = nullablePropertyBinding;
function haltBinding() {
    return {
        get value() {
            throw new Error("Operation not allowed");
        },
        update: function (value) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    throw new Error("Operation not allowed");
                });
            });
        }
    };
}
exports.haltBinding = haltBinding;
function useTemporaryBinding(parent) {
    var _this = this;
    var _a = (0, react_1.useState)(parent.value), tmp = _a[0], setTmp = _a[1];
    var _b = (0, react_1.useState)(false), isDirty = _b[0], setDirty = _b[1];
    (0, react_1.useEffect)(function () {
        setTmp(parent.value);
        setDirty(false);
    }, [parent.value]);
    var saver = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, parent.update(tmp)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var binding = {
        value: tmp,
        update: function (val) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setTmp(val);
                setDirty(true);
                return [2 /*return*/];
            });
        }); }
    };
    return [binding, saver, isDirty];
}
exports.useTemporaryBinding = useTemporaryBinding;
