import { jsx as _jsx } from "react/jsx-runtime";
import { createNullableContext, useNullableContext } from "@pltk/components";
const WidgetIdContext = createNullableContext("Widget ID not initialized");
const WidgetDefinitionContext = createNullableContext("Widget definition not initialized");
export function WidgetContextProvider(props) {
    return _jsx(WidgetDefinitionContext.Provider, Object.assign({ value: props.widgetDefinition }, { children: _jsx(WidgetIdContext.Provider, Object.assign({ value: { panelId: props.panelId, widgetId: props.widgetId } }, { children: props.children })) }));
}
const InternalWidgetConfigContext = createNullableContext("Widget config only available in Widget definition");
export function useWidgetConfigInternal() {
    return useNullableContext(InternalWidgetConfigContext);
}
export function WidgetConfigInternalProvider(props) {
    return _jsx(InternalWidgetConfigContext.Provider, Object.assign({ value: props.configBinding }, { children: props.children }));
}
