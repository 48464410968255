"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unwrapAsyncBinding = exports.unwrapAsyncSubs = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var components_1 = require("./components");
function unwrapAsyncSubs(subs, renderer, options) {
    var _a;
    if (subs.status === "pending") {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (_a = options === null || options === void 0 ? void 0 : options.pendingElement) !== null && _a !== void 0 ? _a : (0, jsx_runtime_1.jsx)(components_1.Loading, {}) });
    }
    else {
        return renderer(subs.value);
    }
}
exports.unwrapAsyncSubs = unwrapAsyncSubs;
function unwrapAsyncBinding(subs, renderer, options) {
    var _a;
    if (subs.status === "pending") {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (_a = options === null || options === void 0 ? void 0 : options.pendingElement) !== null && _a !== void 0 ? _a : (0, jsx_runtime_1.jsx)(components_1.Loading, {}) });
    }
    else {
        return renderer(subs.binding);
    }
}
exports.unwrapAsyncBinding = unwrapAsyncBinding;
